import { useState } from "react";

export default function useInputState(initialValue = "") {
  const [state, setState] = useState(initialValue);
  const handleChange = (e) => {
    if (typeof e === "string" || typeof e === "number") {
      setState(e);
    } else {
      setState(e.target.value);
    }
  };
  const handleReset = () => {
    setState(initialValue);
  };

  return [state, handleChange, handleReset];
}
