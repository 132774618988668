export const calculateTotalCreditsDebits = (
  transactions,
  startingCredit = 0,
  startingDebit = 0
) => {
  let totalCredit = startingCredit;
  let totalDebit = startingDebit;

  for (let i = 0; i < transactions.length; i++) {
    if (transactions[i].type === "Credit") {
      totalCredit += transactions[i].amount;
    } else {
      totalDebit += transactions[i].amount;
    }
  }
  return [totalCredit, totalDebit];
};
