import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme
} from "@mui/material";

import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const IncomeExpensesChart = ({ dataHistory, ...props }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const monthsMap = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec"
  };
  const data = {
    labels: [],
    datasets: [
      {
        backgroundColor: theme.palette.primary.light,
        barPercentage: 0.6,
        borderRadius: 2,
        categoryPercentage: 0.6,
        data: [],
        label: t("Income")
      },
      {
        backgroundColor: theme.palette.secondary.light,
        barPercentage: 0.6,
        borderRadius: 2,
        categoryPercentage: 0.6,
        data: [],
        label: t("Expense")
      }
    ]
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0.1 },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        labels: { boxWidth: 30, font: { family: theme.customVars.urduFont } }
      }
    },
    scales: {
      x: {
        ticks: {
          fontColor: theme.palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0,
          callback: (value) => {
            if (value >= 1000000000 || value <= -1000000000) {
              return value / 1e9 + "B";
            } else if (value >= 1000000 || value <= -1000000) {
              return value / 1e6 + "M";
            } else if (value >= 1000 || value <= -1000) {
              return value / 1e3 + "K";
            }
            return value;
          }
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary
    }
  };

  let currentDate = new Date(new Date().setDate(1));

  let chartDate = new Date(
    new Date().setFullYear(currentDate.getFullYear() - 1)
  );

  chartDate.setDate(1);

  let monthsToChart = [];
  while (chartDate <= currentDate) {
    monthsToChart.push(chartDate.toISOString().split("T")[0].slice(0, 7));
    chartDate.setMonth(chartDate.getMonth() + 1);
  }

  monthsToChart.forEach((k) => {
    data.labels.push(monthsMap[k.slice(5)]);
    data.datasets[0].data.push(dataHistory[k] ? dataHistory[k][0] : 0);
    data.datasets[1].data.push(dataHistory[k] ? dataHistory[k][1] : 0);
  });

  return (
    <Card data-testid="chart">
      <CardHeader
        title={t("incomeVsExpenses")}
        titleTypographyProps={{
          sx:
            theme.direction === "rtl"
              ? { fontFamily: theme.customVars.urduFont }
              : {}
        }}
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            position: "relative",
            height: 300
          }}
        >
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

IncomeExpensesChart.propTypes = {
  dataHistory: PropTypes.object.isRequired
};

export default IncomeExpensesChart;
