import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";

import { useTranslation } from "react-i18next";
import AccountDialog from "../Dialogs/AccountDialog";

const AccountsListToolbar = (props) => {
  const { handleAddAccountSubmit } = props;

  const [openAddAccountDialog, setOpenAddAccountDialog] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();

  const handleAddAccountDialogOpen = () => {
    setOpenAddAccountDialog(true);
  };
  const handleAddAccountDialogClose = () => {
    setOpenAddAccountDialog(false);
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 }
        }}
      >
        <Typography
          sx={{
            flex: "1 1 100%",
            fontFamily: theme.direction === "rtl" && theme.customVars.urduFont
          }}
          variant="subtitle1"
          id="tableTitle"
          component="div"
        >
          {t("accounts_list")}
        </Typography>

        <Tooltip title={t("add_account")}>
          <IconButton
            data-testid="add-account-btn"
            onClick={handleAddAccountDialogOpen}
          >
            <AddIcon fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <AccountDialog
        open={openAddAccountDialog}
        handleClose={handleAddAccountDialogClose}
        handleSubmit={handleAddAccountSubmit}
      />
    </>
  );
};

AccountsListToolbar.propTypes = {
  handleAddAccountSubmit: PropTypes.func.isRequired
};

export default AccountsListToolbar;
