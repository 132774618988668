import authentication from "../../firebase/authentication";
import { firestore } from "../../firebase/firebase";
import {
  LOADING_UI,
  CLEAR_ERRORS,
  SET_ERRORS,
  UNSET_ACCOUNT,
  CLEAR_USER,
  SET_CREDENTIALS
} from "../types";

export const loginUser = (email, password) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: LOADING_UI });

    authentication
      .logIn(email, password)
      .then((user) => {
        resolve(user.uid);
      })
      .catch((err) => {
        console.log("login error");
        console.error(err);
        dispatch({ type: SET_ERRORS, payload: err });
        reject(err);
      });
  });
};

export const logoutUser = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: LOADING_UI });
    authentication
      .logOut()
      .then(() => {
        dispatch({ type: CLEAR_USER });
        dispatch(clearErrors());
        resolve();
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SET_ERRORS, payload: err });
        reject();
      });
  });
};

export const changeCredentials = (newCredentials) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const userRef = firestore.collection("users").doc(newCredentials.userId);
    newCredentials.editedAt = new Date().toISOString();

    userRef
      .set(newCredentials, { merge: true })
      .then(() => {
        dispatch({ type: SET_CREDENTIALS, payload: newCredentials });
        resolve();
      })
      .catch((err) => {
        console.error(err);
        reject(err.message);
      });
  });
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export const clearAccount = () => (dispatch) => {
  dispatch({ type: UNSET_ACCOUNT });
};
