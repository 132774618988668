import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box, useTheme } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import HkBotton from "../CustomizedMui/HkButton";
import HkTextField from "../CustomizedMui/HkTextField";
import AlertDialog from "../Dialogs/AlertDialog";
import authentication from "../../firebase/authentication";

const useStyles = makeStyles((theme) => ({
  error: theme.customClasses.error,
  progressCircle: theme.customClasses.progressCircle
}));
function PasswordChange(props) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const validationSchema = yup.object({
    password: yup
      .string(t("password"))
      .min(8, "Password must be atleast 8 characters.")
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Password confirmation is required")
  });
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      authentication
        .changePassword(values.password)
        .then(() => {
          setLoading(false);
          setOpenAlertDialog(true);
        })
        .catch((err) => {
          console.error(err);
          setErrors({ general: err.message });
          setLoading(false);
        });
    }
  });

  useEffect(() => {
    return () => formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit} id="password-form">
        <Card>
          <CardHeader
            title={t("change_password")}
            titleTypographyProps={{
              sx: { fontFamily: theme.customVars.urduFont },
              variant: theme.direction === "rtl" ? "h6" : "h5"
            }}
          />
          <Divider />
          <CardContent>
            <HkTextField
              data-testid="password"
              id="password"
              name="password"
              type="password"
              placeholder={t("password")}
              margin="normal"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              value={formik.values.password}
              onChange={formik.handleChange}
              fullWidth
            />
            <HkTextField
              data-testid="confirmPassword"
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder={t("confirm_password")}
              margin="normal"
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              fullWidth
            />
            {errors && (
              <Typography className={classes.error} variant="body2">
                <Box
                  component="span"
                  sx={
                    theme.direction === "rtl"
                      ? { fontFamily: theme.customVars.urduFont }
                      : {}
                  }
                >
                  {errors.general}
                </Box>
              </Typography>
            )}
          </CardContent>
          <Divider />

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 2
            }}
          >
            <HkBotton
              data-testid="password-submit-button"
              color="primary"
              form="password-form"
              type="submit"
              variant="contained"
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  className={classes.progressCircle}
                  size={25}
                  color="secondary"
                />
              )}
              {t("change_password_submit")}
            </HkBotton>
          </Box>
        </Card>
      </form>
      <AlertDialog
        open={openAlertDialog}
        handleDialogClose={() => setOpenAlertDialog(false)}
        title={t("change_password_alert_title")}
        closeButtonText={t("close")}
        closeButtonColor={"success"}
      >
        {t("change_password_alert_msg")}
      </AlertDialog>
    </>
  );
}

export default PasswordChange;
