import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function ResponsiveDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { open, handleClose, dialogTitle, dialogContent, dialogActions } =
    props;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle sx={{ px: "16px" }} id="responsive-dialog-title">
        {dialogTitle}
      </DialogTitle>
      <DialogContent sx={{ px: "16px" }}>{dialogContent}</DialogContent>
      <DialogActions sx={{ mb: 1, px: "16px" }}>{dialogActions}</DialogActions>
    </Dialog>
  );
}
