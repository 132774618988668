import React from "react";
import { Helmet } from "react-helmet";
import { Box } from "@material-ui/core";
import DashboardContainer from "../components/Dashboard/DashboardContainer";

function Dashboard() {
  return (
    <>
      <Helmet>
        <title>Dashboard | HisaabKitaab</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3
        }}
      >
        <DashboardContainer />
      </Box>
    </>
  );
}

export default Dashboard;
