import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

//MUI
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material";
import {
  Menu as MenuIcon,
  Language as LanguageIcon,
  ExitToApp as ExitToAppIcon
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";

/*****************************IMPORTS END**************************************/

const DashboardNavbar = ({
  onMobileNavOpen,
  setLanguage,
  companyName,
  language,
  handleLogout,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setLangEnglish = () => {
    setLanguage("en");

    handleClose();
  };
  const setLangUrdu = () => {
    setLanguage("ur");

    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <IconButton
          data-testid="menu-btn"
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onMobileNavOpen}
          sx={{
            mr: theme.direction === "ltr" ? 2 : 0,
            ml: theme.direction === "rtl" ? 2 : 0,
            display: { lg: "none" }
          }}
        >
          <MenuIcon />
        </IconButton>
        <RouterLink to="/">
          <Typography variant="h6" component="div" sx={{ color: "#fff" }}>
            {companyName}
          </Typography>
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />

        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <LanguageIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={setLangEnglish}
            sx={{
              fontWeight: language === "en" ? "bold" : "inherit"
            }}
          >
            English
          </MenuItem>
          <MenuItem
            onClick={setLangUrdu}
            sx={{
              fontWeight: language === "ur" ? "bold" : "inherit"
            }}
          >
            Urdu
          </MenuItem>
        </Menu>
        <Tooltip title="Logout">
          <IconButton
            color="inherit"
            aria-label="log out"
            edge="end"
            size="large"
            onClick={handleLogout}
          >
            <ExitToAppIcon
              sx={language === "ur" ? { transform: "scaleX(-1)" } : {}}
            />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  handleLogout: PropTypes.func.isRequired
};

export default DashboardNavbar;
