import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import TransactionsList from "./TransactionsList";
import AccountDetailsHeader from "./AccountDetailsHeader";
import { connect } from "react-redux";
import { calculateTotalCreditsDebits } from "../../helpers/accountHelpers";
import { deleteAccount } from "../../redux/actions/dataActions";

function AccountContainer({ user, accountId, deleteAccount }) {
  const [account, setAccount] = useState({ transactions: [] });

  useEffect(() => {
    if (accountId && user.accounts.hasOwnProperty(accountId)) {
      setAccount(user.accounts[accountId]);
    }
    return () => {
      setAccount({ transactions: [] });
    };
  }, [accountId, user.accounts]);

  const [accountCredits, accountDebits] = useMemo(() => {
    if (account.transactions) {
      return calculateTotalCreditsDebits(account.transactions);
    }
  }, [account.transactions]);

  const accountBalance = accountCredits - accountDebits;

  const handleAccountDelete = () => {
    return deleteAccount(accountId);
  };

  return (
    !!account && (
      <>
        <AccountDetailsHeader
          account={account}
          accountBalance={accountBalance}
          accountCredits={accountCredits}
          accountDebits={accountDebits}
          handleAccountDelete={handleAccountDelete}
        />
        <Box sx={{ pt: 3 }}>
          <TransactionsList
            transactions={account.transactions}
            accountId={accountId}
            userId={user.credentials.userId}
          />
        </Box>
      </>
    )
  );
}

AccountContainer.propTypes = {
  user: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired,
  deleteAccount: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, { deleteAccount })(AccountContainer);
