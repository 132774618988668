import React from 'react';
import TextField from '@mui/material/TextField';

//Mui
import { useTheme } from '@mui/material/styles';

export default function HkTextField(props) {
  const {
    inputProps: inputProp,
    InputLabelProps: inputLabelProps,
    ...rest
  } = props;

  const theme = useTheme();
  return (
    <TextField
      {...rest}
      variant="standard"
      inputProps={
        theme.direction === 'rtl'
          ? {
              ...inputProp,
              sx: {
                ...inputProp?.sx,
                fontFamily: theme.customVars.urduFont,
                lineHeight: '1em',
                transform: 'translateY(-2px)'
              }
            }
          : { ...inputProp }
      } // font fontFamily of input text
      InputLabelProps={
        theme.direction === 'rtl'
          ? {
              ...inputLabelProps,
              sx: {
                ...inputLabelProps?.sx,
                fontFamily: theme.customVars.urduFont
              }
            }
          : { ...inputLabelProps }
      } // font fontFamily of input label
      FormHelperTextProps={
        theme.direction === 'rtl' ? { sx: { textAlign: 'right' } } : {}
      }
    >
      {props.children}
    </TextField>
  );
}
