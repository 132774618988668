import { Box, styled } from "@mui/system";
import { Button, Typography } from "@mui/material";

export const FormContainer = styled(Box)({
  "& .MuiTextField-root": {
    margin: "8px"
  },
  height: "420px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
});

export const InputsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "2px"
});

export const ActionsContainer = styled("div")({
  padding: "8px"
});

export const MutedLink = styled(Button)({
  fontSize: "12px",
  color: "rgba(200,200,200,0.8)",
  fontWeight: "500",
  textDecoration: "none",
  padding: "0px 8px",
  marginTop: "-8px",
  alignSelf: "end"
});

export const SubmitButton = styled(Button)({
  marginTop: "8px",
  borderRadius: "50px",
  background: "rgb(8,78,138)",
  // eslint-disable-next-line no-dupe-keys
  background:
    "linear-gradient(90deg, rgba(8,78,138,1) 0%, rgba(25,118,210,1) 100%)"
});

export const MutedText = styled(Typography)({
  fontSize: "12px",
  color: "rgba(200,200,200,0.8)",
  fontWeight: "500",
  textDecoration: "none",
  padding: "0px 8px",
  marginTop: "10px",
  textAlign: "center"
});

export const BoldLink = styled("span")({
  textDecoration: "none",
  fontWeight: "700",
  color: "rgb(8,78,138)",
  fornSize: "13px",
  cursor: "pointer"
});
