import React from "react";
import { useTheme } from "@mui/system";
import { useNavigate } from "react-router";
import { TableCell, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ProductRow(props) {
  const { product, labelId } = props;

  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const handleRowClick = (event) => {
    navigate(`${product.productId}`);
  };

  return (
    <TableRow
      data-testid="account-row"
      hover
      onClick={(event) => handleRowClick(event)}
      role="checkbox"
      tabIndex={-1}
    >
      <TableCell
        component="th"
        id={labelId}
        scope="row"
        align={theme.direction === "ltr" ? "left" : "right"}
        sx={{
          ...(theme.direction === "rtl" && {
            fontFamily: theme.customVars.urduFont
          })
        }}
      >
        {product.name}
      </TableCell>
      <TableCell dir="ltr" align={theme.direction === "ltr" ? "left" : "right"}>
        {product.quantityOnHand}
      </TableCell>
      <TableCell
        align={theme.direction === "ltr" ? "left" : "right"}
        sx={{
          ...(theme.direction === "rtl" && {
            fontFamily: theme.customVars.urduFont
          })
        }}
      >
        {t(product.unit)}
      </TableCell>

      <TableCell dir="ltr" align={theme.direction === "ltr" ? "left" : "right"}>
        {product.price}
      </TableCell>
      <TableCell dir="ltr" align={theme.direction === "ltr" ? "left" : "right"}>
        {(product.price * product.quantityOnHand).toLocaleString()}
      </TableCell>
    </TableRow>
  );
}
