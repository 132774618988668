import { Box } from "@mui/system";
import React from "react";

function Marginer(props) {
  const { direction, margin } = props;

  const m = typeof margin === "string" ? margin : `${margin}px`;

  return <Box sx={direction === "horizontal" ? { width: m } : { height: m }} />;
}

Marginer.defaultProps = {
  direction: "horizontal"
};

export { Marginer };
