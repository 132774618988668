import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

firebase.initializeApp({
  apiKey:
    process.env.REACT_APP_SERVER === 'PRODUCTION'
      ? process.env.REACT_APP_FB_PROD_API_KEY
      : process.env.REACT_APP_FB_DEV_API_KEY,
  authDomain:
    process.env.REACT_APP_SERVER === 'PRODUCTION'
      ? process.env.REACT_APP_FB_PROD_AUTH_DOMAIN
      : process.env.REACT_APP_FB_DEV_AUTH_DOMAIN,
  databaseURL: '',
  projectId:
    process.env.REACT_APP_SERVER === 'PRODUCTION'
      ? process.env.REACT_APP_FB_PROD_PROJECT_ID
      : process.env.REACT_APP_FB_DEV_PROJECT_ID,
  storageBucket:
    process.env.REACT_APP_SERVER === 'PRODUCTION'
      ? process.env.REACT_APP_FB_PROD_STORAGE_BUCKET
      : process.env.REACT_APP_FB_DEV_STORAGE_BUCKET,
  messagingSenderId:
    process.env.REACT_APP_SERVER === 'PRODUCTION'
      ? process.env.REACT_APP_FB_PROD_MESSAGING_SENDER_ID
      : process.env.REACT_APP_FB_DEV_MESSAGING_SENDER_ID,
  appId:
    process.env.REACT_APP_SERVER === 'PRODUCTION'
      ? process.env.REACT_APP_FB_PROD_APP_ID
      : process.env.REACT_APP_FB_DEV_APP_ID,
  measurementId:
    process.env.REACT_APP_SERVER === 'PRODUCTION'
      ? process.env.REACT_APP_FB_PROD_MEASUREMENT_ID
      : process.env.REACT_APP_FB_DEV_MEASUREMENT_ID
});

export default firebase;

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

// if (window.location.hostname === "localhost") {
//   firestore.useEmulator("localhost", 8080);
//   auth.useEmulator("http://localhost:9099");
//   storage.useEmulator("localhost", 9199);
// }
