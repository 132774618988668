import React from "react";
import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/system";

export default function Debits({ amount, ...rest }) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Card sx={{ height: "100%" }} {...rest}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
              sx={
                theme.direction === "rtl"
                  ? {
                      fontFamily: theme.customVars.urduFont,
                      pb: 1
                    }
                  : {}
              }
            >
              {t("Debits")}
            </Typography>
            <Typography
              data-testid="account-debits"
              color="textPrimary"
              variant="h5"
            >
              {amount.toLocaleString()}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: red[400],
                height: 56,
                width: 56
              }}
            >
              <ArrowUpwardIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
