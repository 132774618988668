import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { connect } from "react-redux";
import { useTheme } from "@mui/system";

function AccountsAutocomplete({
  accounts,
  handleAccountChange,
  defaultOption,
  selectedValue,
  placeholderText
}) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = React.useState(null);

  const theme = useTheme();
  useEffect(() => {
    let list = Object.values(accounts)
      .filter((acc) => acc.category !== "Inventory")
      .map((acc) => ({
        name: acc.name,
        id: acc.accountId
      }));
    list.unshift(defaultOption);

    setOptions(list);
  }, [accounts, defaultOption]);

  useEffect(() => {
    if (selectedValue) {
      //make sure it is in the valid option value
      let selectedOption = options.find(({ id }) => selectedValue === id);

      if (selectedOption) setValue(selectedOption);
    }
  }, [selectedValue, options]);

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        handleAccountChange(newValue?.id ?? defaultOption.id);
      }}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      id="accounts-autocomplete"
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholderText ?? ""}
          variant="standard"
          sx={{ input: { fontFamily: theme.customVars.urduFont } }}
        />
      )}
    />
  );
}

const mapStateToProps = (state) => ({
  accounts: state.user.accounts
});

export default connect(mapStateToProps)(AccountsAutocomplete);
