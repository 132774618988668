import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { CircularProgress, TextField, Typography } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import {
  FormContainer,
  InputsContainer,
  MutedLink,
  ActionsContainer,
  SubmitButton,
  MutedText,
  BoldLink
} from "./common";
import { Marginer } from "../Marginer/Marginer";
import { connect } from "react-redux";
import { Box } from "@mui/system";
import { loginUser } from "../../redux/actions/userActions";
import { getUserData } from "../../redux/actions/dataActions";

function LoginForm({ playAnimation, activate, loginUser, getUserData }) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const switchToSignup = () => {
    playAnimation();

    setTimeout(() => {
      activate("signup");
    }, 700);
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email address is required."),
    password: yup
      .string()
      .min(8, "Password is too short.")
      .required("Password is required")
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      loginUser(values.email, values.password)
        .then((uid) => {
          getUserData(uid).catch((err) => {
            console.error(err);
            setErrors({ general: err });
            setLoading(false);
          });
        })
        .catch((err) => {
          console.error(err);
          setErrors({ general: err.message });
          setLoading(false);
        });
    }
  });

  return (
    <FormContainer
      component="form"
      onSubmit={formik.handleSubmit}
      id="login-form"
      autoComplete="off"
    >
      <Marginer direction="vertical" margin="30px" />
      <InputsContainer>
        <TextField
          id="email"
          name="email"
          type="email"
          placeholder="Email"
          label="Email"
          variant="outlined"
          margin="normal"
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <TextField
          id="password"
          name="password"
          type="password"
          label="Password"
          placeholder="Password"
          margin="normal"
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          onChange={formik.handleChange}
        />
        <MutedLink component={RouterLink} to="/forgotPassword">
          Forgot Password?
        </MutedLink>
        {errors && (
          <Typography sx={{ color: "red", fontSize: "0.8rem" }} variant="body2">
            <Box component="span">{errors.general}</Box>
          </Typography>
        )}
      </InputsContainer>

      <ActionsContainer>
        <SubmitButton
          type="submit"
          variant="contained"
          form="login-form"
          disabled={loading}
          fullWidth
        >
          {loading && (
            <CircularProgress
              sx={{ position: "absolute" }}
              size={25}
              color="secondary"
            />
          )}
          Login
        </SubmitButton>
        <MutedText>
          Don't have an Account?{" "}
          <BoldLink onClick={switchToSignup}> Sign up</BoldLink>
        </MutedText>
      </ActionsContainer>
    </FormContainer>
  );
}

export default connect(null, { loginUser, getUserData })(LoginForm);
