import { Container } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import ProductContainer from "../components/ProductDetails/ProductContainer";

function AccountDetailsPage() {
  let { productId } = useParams();

  return (
    <>
      <Helmet>
        <title>Product Details | HisaabKitaab</title>
      </Helmet>
      <Box sx={{ minHeight: "100%", py: 3 }}>
        <Container maxWidth={false}>
          <ProductContainer productId={productId} />
        </Container>
      </Box>
    </>
  );
}

export default AccountDetailsPage;
