import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import React from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EastIcon from "@mui/icons-material/East";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/system";

function UserBalance({ balance, ...rest }) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Card data-testid="user-balance" sx={{ height: "100%" }} {...rest}>
      <CardContent p="2">
        <Grid container spacing={3}>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              sx={{
                backgroundColor: "rgba(36, 153, 239, 0.2)",
                height: 70,
                width: 70
              }}
            >
              <AccountBalanceIcon sx={{ color: "rgb(36, 153, 239)" }} />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography
              data-testid="balance"
              dir="ltr"
              color="textPrimary"
              variant="h4"
            >
              {balance}
            </Typography>
            <Typography
              color="textSecondary"
              variant="subtitle1"
              sx={
                theme.direction === "rtl"
                  ? {
                      fontFamily: theme.customVars.urduFont,
                      pb: 1
                    }
                  : {}
              }
            >
              {t("cash_balance")}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions sx={{ px: 4 }}>
        <Button
          data-testid="see-all-accounts-btn"
          component={RouterLink}
          sx={{
            color: "rgb(36, 153, 239)",
            textTransform: "none",
            fontFamily:
              theme.direction === "rtl" ? theme.customVars.urduFont : ""
          }}
          endIcon={
            <EastIcon
              sx={
                theme.direction === "rtl"
                  ? { transform: "scaleX(-1) scale(1.2) translate(8px, 3px)" }
                  : {}
              }
            />
          }
          to={"/app/accounts"}
        >
          {t("see_all_accounts")}
        </Button>
      </CardActions>
    </Card>
  );
}

export default UserBalance;
