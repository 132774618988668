import React from 'react';
import { TablePagination } from '@mui/material';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';

//************************************************************** */
export default function HkTablePaginator({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, 50, 100, 200, count].sort(
        (a, b) => a - b
      )}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      labelRowsPerPage={t('rows_per_page')}
      sx={
        theme.direction === 'rtl'
          ? {
              '& .MuiTablePagination-selectLabel': {
                fontFamily: theme.customVars.urduFont
              },
              '& .MuiInputBase-root.MuiTablePagination-input': {
                marginRight: '8px',
                marginLeft: '32px',
                fontFamily: theme.customVars.urduFont
              },
              '& svg': {
                marginTop: '3px'
              },
              '& .MuiTablePagination-displayedRows': {
                direction: 'ltr',
                fontFamily: theme.customVars.urduFont
              }
            }
          : {}
      }
    />
  );
}
