import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { getComparator, stableSort } from '../../helpers/tableHelpers';
import OrderRow from './OrderRow';
import OrdersTableHeader from './OrdersTableHeader';

//****************************************************************/

function OrdersTable(props) {
  //*****************    props     ********************************/
  const {
    productOrders,
    selected,
    handleSelectAllClick,
    handleSelectClick,
    handleRowClick,
    page,
    rowsPerPage,
    unit
  } = props;

  //*****************    state     ********************************/
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');

  //*********************  handler functions  *********************/
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getSortedOrders = () => {
    return stableSort(productOrders, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - productOrders.length) : 0;

  //*********************  render  *********************/
  return (
    <Table sx={{ minWidth: 950 }} aria-labelledby="tableTitle" size="medium">
      <OrdersTableHeader
        numSelected={selected.length}
        order={order}
        orderBy={orderBy}
        onSelectAllClick={handleSelectAllClick}
        onRequestSort={handleRequestSort}
        rowCount={productOrders.length}
        unit={unit}
      />
      <TableBody>
        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
        {getSortedOrders().map((productOrder, index) => {
          const isItemSelected = isSelected(productOrder.orderId);
          const labelId = `enhanced-table-checkbox-${index}`;
          let id = productOrder.orderId;

          return (
            <OrderRow
              productOrder={productOrder}
              handleSelectClick={handleSelectClick}
              handleRowClick={handleRowClick}
              labelId={labelId}
              isItemSelected={isItemSelected}
              key={id}
            />
          );
        })}
        {emptyRows > 0 && (
          <TableRow
            style={{
              height: 53 * emptyRows
            }}
          >
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default OrdersTable;
