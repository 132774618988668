import React from "react";
import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { red, green, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/system";

export default function Balance({ balance, ...rest }) {
  const { t } = useTranslation();
  const theme = useTheme();
  console.log();
  let balanceStr = `${Math.abs(balance).toLocaleString()}`;
  let color = grey[400];

  if (balance > 0) {
    balanceStr = `${balanceStr} (${t("Cr")})`;
    color = green[400];
  } else if (balance < 0) {
    balanceStr = `${balanceStr} (${t("Db")})`;
    color = red[400];
  }

  return (
    <Card sx={{ height: "100%" }} {...rest}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
              sx={
                theme.direction === "rtl"
                  ? {
                      fontFamily: theme.customVars.urduFont,
                      pb: 1
                    }
                  : {}
              }
            >
              {t("balance")}
            </Typography>
            <Typography
              data-testid="account-balance"
              dir="ltr"
              color="textPrimary"
              variant="h5"
              sx={{ color: balance === 0 ? "inherit" : color }}
            >
              {balanceStr}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: color,
                height: 56,
                width: 56
              }}
            >
              <AccountBalanceWalletIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
