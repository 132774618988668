import React, { useState, useEffect } from 'react';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/system';

export default function TransactionRow(props) {
  const {
    transaction: transactionProp,
    handleSelectClick,
    isItemSelected,
    handleRowClick,
    labelId
  } = props;
  const [transaction, setTransaction] = useState({});

  const theme = useTheme();

  useEffect(() => {
    setTransaction(transactionProp);
    return () => {
      setTransaction([]);
    };
  }, [transactionProp]);

  return (
    <TableRow
      hover
      onClick={(event) => handleRowClick(event, transaction.transactionId)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        {!transaction.hasOwnProperty('orderId') && (
          <Checkbox
            onClick={(event) =>
              handleSelectClick(event, transaction.transactionId)
            }
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId
            }}
            disabled={transaction.hasOwnProperty('orderId')}
          />
        )}
      </TableCell>
      <TableCell
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        align={theme.direction === 'ltr' ? 'left' : 'right'}
      >
        {transaction.date}
      </TableCell>

      <TableCell
        dir="ltr"
        align={theme.direction === 'ltr' ? 'left' : 'right'}
        sx={{
          color: 'red'
        }}
      >
        {transaction.debit > 0 ? transaction.debit.toLocaleString() : ''}
      </TableCell>
      <TableCell
        dir="ltr"
        align={theme.direction === 'ltr' ? 'left' : 'right'}
        sx={{
          color: 'limegreen'
        }}
      >
        {transaction.credit > 0 ? transaction.credit.toLocaleString() : ''}
      </TableCell>
      <TableCell
        align={theme.direction === 'ltr' ? 'left' : 'right'}
        sx={{
          fontFamily:
            theme.direction !== 'ltr' ? theme.customVars.urduFont : 'inherit'
        }}
      >
        {transaction.details}
      </TableCell>
    </TableRow>
  );
}
