import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router";

//MUI
import { styled, useTheme } from "@mui/system";

//components
import DashboardDrawer from "../components/Navbar/DashboardDrawer";
import DashboardNavbar from "../components/Navbar/DashboardNavbar";

//i18n
import { useTranslation } from "react-i18next";

//redux
import { connect } from "react-redux";
import { logoutUser } from "../redux/actions/userActions";

/********************************IMPORTS END***************************/

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#f9fafc",
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%"
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: theme.direction === "ltr" ? 256 : 0,
    paddingRight: theme.direction === "rtl" ? 256 : 0
  }
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden"
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto"
});

const DashboardLayout = ({ userCredentials, logoutUser }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    i18n.changeLanguage(language);
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, i18n]);

  const handleLogout = () => {
    logoutUser().then(() => navigate("/login"));
  };

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        language={language}
        setLanguage={setLanguage}
        handleLogout={handleLogout}
        companyName={userCredentials.organization}
      />
      <DashboardDrawer
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        user={userCredentials}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

const mapStateToProps = (state) => ({
  userCredentials: state.user.credentials
});

export default connect(mapStateToProps, { logoutUser })(DashboardLayout);
