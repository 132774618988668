import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export default function AccountRow(props) {
  const { account, labelId } = props;

  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const handleRowClick = (e) => {
    navigate(`${account.accountId}`);
  };

  let balanceStr =
    Math.abs(account.balance).toLocaleString() +
    (account.balance < 0
      ? ` /- ${t("Db")}`
      : account.balance > 0
      ? ` /- ${t("Cr")}`
      : ` /-`);

  return (
    <TableRow
      data-testid="account-row"
      hover
      onClick={handleRowClick}
      role="table-row"
      tabIndex={-1}
    >
      <TableCell
        component="th"
        id={labelId}
        scope="row"
        align={theme.direction === "ltr" ? "left" : "right"}
        sx={{ fontFamily: theme.customVars.urduFont }}
      >
        {account.name}
      </TableCell>
      <TableCell
        align={theme.direction === "ltr" ? "left" : "right"}
        dir="ltr"
        sx={{
          color:
            account.balance < 0
              ? "red"
              : account.balance > 0
              ? "limegreen"
              : "inherit"
        }}
      >
        {balanceStr}
      </TableCell>
      <TableCell
        align={theme.direction === "ltr" ? "left" : "right"}
        sx={{ fontFamily: theme.customVars.urduFont }}
      >
        {t(account.category)}
      </TableCell>
      <TableCell dir="ltr" align={theme.direction === "ltr" ? "left" : "right"}>
        {account.phoneNum}
      </TableCell>
      <TableCell dir="ltr" align={theme.direction === "ltr" ? "left" : "right"}>
        {account.email}
      </TableCell>
    </TableRow>
  );
}
