import React, { useState } from "react";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import TransactionsTableHeader from "./TransactionsTableHeader";
import TransactionRow from "./TransactionRow";
import { getComparator, stableSort } from "../../helpers/tableHelpers";
//****************************************************************/

function TransactionsTable(props) {
  //*****************    props     ********************************/
  const {
    transactions,
    selected,
    handleSelectAllClick,
    handleSelectClick,
    handleRowClick,
    page,
    rowsPerPage
  } = props;

  //*****************    state     ********************************/
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date");

  //*********************  handler functions  *********************/
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getSortedTransactions = () => {
    return stableSort(transactions, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - transactions.length) : 0;

  //*********************  render  *********************/
  return (
    <Table
      sx={{ minWidth: 750 }}
      aria-labelledby="transactionsTable"
      size="medium"
    >
      <TransactionsTableHeader
        numSelected={selected.length}
        order={order}
        orderBy={orderBy}
        onSelectAllClick={handleSelectAllClick}
        onRequestSort={handleRequestSort}
        rowCount={transactions.length}
      />
      <TableBody>
        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
       rows.slice().sort(getComparator(order, orderBy)) */}
        {getSortedTransactions().map((transaction, index) => {
          const isItemSelected = isSelected(transaction.transactionId);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TransactionRow
              transaction={transaction}
              handleSelectClick={handleSelectClick}
              handleRowClick={handleRowClick}
              isItemSelected={isItemSelected}
              labelId={labelId}
              key={transaction.transactionId}
            />
          );
        })}
        {emptyRows > 0 && (
          <TableRow
            style={{
              height: 53 * emptyRows
            }}
          >
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default TransactionsTable;
