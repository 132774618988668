import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import PropTypes from "prop-types";
import React from "react";
import { useTheme } from "@emotion/react";
import { NavLink as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BankAccounts = ({ topBankAccounts, bankBalance }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  while (topBankAccounts.length < 3) {
    topBankAccounts.push({
      name: "---",
      balance: "---",
      accountId: topBankAccounts.length
    });
  }

  const bulletColors = [
    "rgb(47, 62, 177)",
    "rgb(12, 124, 213)",
    "rgb(123, 198, 126)"
  ];
  return (
    <Card
      data-testid="total-bank-balance"
      sx={{
        padding: "8px"
      }}
    >
      <CardContent>
        <Typography
          variant={theme.direction === "rtl" ? "subtitle2" : "overline"}
          gutterBottom={theme.direction === "rtl"}
          sx={{
            fontWeight: "700",
            color: "rgb(101, 116, 139)",
            lineHeight: 2.5,
            fontFamily:
              theme.direction === "rtl"
                ? theme.customVars.urduFont
                : theme.typography.fontFamily
          }}
        >
          {t("totalBankBalance")}
        </Typography>
        <Typography
          data-testid="bank-balance"
          variant="h4"
          sx={{
            fontWeight: 700,
            lineHeight: "1.375",
            fontSize: { xs: "1.5rem", sm: "1.8rem" }
          }}
        >
          {bankBalance.toLocaleString()}
        </Typography>
        <Divider sx={{ margin: "16px 0px", flexShrink: 0 }} />
        <Typography
          variant="overline"
          sx={{
            fontWeight: "700",
            color: "rgb(101, 116, 139)",
            lineHeight: 2.5,
            fontFamily:
              theme.direction === "rtl"
                ? theme.customVars.urduFont
                : theme.typography.fontFamily
          }}
        >
          {t("topAccounts")}
        </Typography>
        <List
          sx={{
            padding: "8px 0px 0px"
          }}
        >
          {topBankAccounts.map((acc, i) => (
            <ListItem key={acc.accountId} sx={{ px: 0 }}>
              <ListItemText>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Box
                      sx={{
                        border: `3px solid ${bulletColors[i]}`,
                        borderRadius: "50%",
                        height: "16px",
                        mr: theme.direction === "ltr" ? 1 : 0,
                        ml: theme.direction === "ltr" ? 0 : 1,
                        width: "16px"
                      }}
                    />
                    <Typography variant="subtitle2">{acc.name}</Typography>
                  </Box>
                  <Typography variant="subtitle2">
                    {acc.balance.toLocaleString()}
                  </Typography>
                </Box>
              </ListItemText>
            </ListItem>
          ))}
        </List>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            pt: 2
          }}
        >
          <Button
            component={RouterLink}
            sx={{
              color: "primary.main",
              textTransform: "none",
              fontFamily:
                theme.direction === "rtl" ? theme.customVars.urduFont : ""
            }}
            endIcon={
              <EastIcon
                sx={
                  theme.direction === "rtl"
                    ? { transform: "scaleX(-1) scale(1.2) translate(8px, 3px)" }
                    : {}
                }
              />
            }
            to={"/app/accounts"}
          >
            {t("see_all_accounts")}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

BankAccounts.propTypes = {
  bankBalance: PropTypes.number.isRequired,
  topBankAccounts: PropTypes.array.isRequired
};

export default BankAccounts;
