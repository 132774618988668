import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/system";

function AccountsListHeader(props) {
  const { order, orderBy, onRequestSort } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  const headerCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: t("name")
    },
    {
      id: "balance",
      numeric: true,
      disablePadding: false,
      label: t("balance")
    },
    {
      id: "category",
      numeric: false,
      disablePadding: false,
      label: t("category")
    },
    {
      id: "phoneNum",
      numeric: false,
      disablePadding: false,
      label: t("phoneNum")
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: t("email")
    }
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headerCells.map((headerCell) => (
          <TableCell
            key={headerCell.id}
            align={theme.direction === "ltr" ? "left" : "right"}
            padding={headerCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headerCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headerCell.id}
              direction={orderBy === headerCell.id ? order : "asc"}
              onClick={createSortHandler(headerCell.id)}
              dir="ltr"
              sx={{
                fontFamily:
                  theme.direction === "rtl" ? theme.customVars.urduFont : ""
              }}
            >
              {headerCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

AccountsListHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

export default AccountsListHeader;
