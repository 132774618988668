import React, { useState } from "react";
import { Paper, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import LoginForm from "../components/LoginSignup/LoginForm";
import SignupForm from "../components/LoginSignup/SignupForm";
import { motion } from "framer-motion";

const LoginSignupContainer = styled("div")({
  direction: "ltr",
  width: "100%",
  height: "90vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px"
});

const BoxContainer = styled(Paper)({
  minHeight: "580px",
  width: "300px",
  borderRadius: "19px",
  overflow: "hidden"
});

const TopContainer = styled(Box)({
  height: "180px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  padding: "0em 1.8em 2em 1.8em",
  position: "relative"
});

const BackDrop = styled(motion.div)({
  zIndex: "3",
  width: "130%",
  height: "550px",
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  borderRadius: "50%",
  transform: "rotate(80deg)",
  top: "-300px",
  left: "-70px",
  background: "rgb(8,78,138)",
  // eslint-disable-next-line no-dupe-keys
  background:
    "linear-gradient(90deg, rgba(8,78,138,1) 0%, rgba(25,118,210,1) 100%)"
});

const HeaderContainer = styled(Box)({
  flexDirection: "column",
  width: "100%",
  zIndex: 4
});

const HeaderText = styled(Typography)({
  color: "#fff",
  fontSize: "30px"
});

const SmallText = styled(Typography)({
  color: "#fff",
  fontSize: "11px"
});

const BottomContainer = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "0.5em"
});

const backdropVariants = {
  expanded: {
    width: "363%",
    height: "1500px",
    top: "-400px",
    left: "-70px",
    borderRadius: "20%",
    transform: "rotate(70deg)"
  },
  collapsed: {
    width: "130%",
    height: "580px",
    borderRadius: "50%",
    transform: "rotate(80deg)"
  }
};

const expandingTransition = {
  type: "spring",
  duration: 1.8,
  stiffness: 40
};

export default function LoginSignup(props) {
  const [active, setActive] = useState(props.active);
  const [isExpanded, setExpanded] = useState(false);

  const playExpandingAnimation = () => {
    setExpanded(true);
    setTimeout(() => {
      setExpanded(false);
    }, expandingTransition.duration * 1000 - 1200);
  };

  return (
    <LoginSignupContainer>
      <BoxContainer>
        <TopContainer>
          <BackDrop
            initial={false}
            animate={isExpanded ? "expanded" : "collapsed"}
            variants={backdropVariants}
            transition={expandingTransition}
          />
          {active === "login" && (
            <HeaderContainer>
              <HeaderText variant="h3">Welcome</HeaderText>
              <HeaderText variant="h3">Back</HeaderText>
              <SmallText variant="subtitle2">
                Please Login to continue!
              </SmallText>
            </HeaderContainer>
          )}
          {active === "signup" && (
            <HeaderContainer>
              <HeaderText variant="h3">Create</HeaderText>
              <HeaderText variant="h3">Account</HeaderText>
              <SmallText variant="subtitle2">
                Please Signup to continue!
              </SmallText>
            </HeaderContainer>
          )}
        </TopContainer>

        <BottomContainer>
          {active === "login" && (
            <LoginForm
              playAnimation={playExpandingAnimation}
              activate={setActive}
            />
          )}
          {active === "signup" && (
            <SignupForm
              playAnimation={playExpandingAnimation}
              activate={setActive}
            />
          )}
        </BottomContainer>
      </BoxContainer>
    </LoginSignupContainer>
  );
}
