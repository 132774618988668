import React, { useMemo, useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";
import ProductDialog from "../Dialogs/ProductDialog";
import { deleteProduct, editProduct } from "../../redux/actions/dataActions";
import ProductData from "./ProductData";
import SalePurchaseChart from "./SalePurchaseChart";
import { useTheme } from "@mui/system";

/********************************************************* */

function ProductDetailsHeader({ product, handleProductDelete, editProduct }) {
  const [openProductDialog, setOpenProductDialog] = useState(false);

  const theme = useTheme();

  const handleAddAccountDialogOpen = () => {
    setOpenProductDialog(true);
  };

  const handleProductDialogClose = () => {
    setOpenProductDialog(false);
  };

  const handleEditProductSubmit = (newData) => {
    let productData = { ...newData };
    let time = new Date().toISOString();
    productData.editedAt = time;
    productData.productId = product.productId;
    productData.priceUpdatedAt = time;

    return editProduct(productData);
  };

  const { purchases, sales, stockHistory, valueHistory } = useMemo(() => {
    let purchasesData = { value: 0, quantity: 0, avgPrice: 0 };
    let salesData = { value: 0, quantity: 0, avgPrice: 0 };

    let history = { value: {}, stock: {} };
    let historyStartDate = new Date();
    historyStartDate.setFullYear(historyStartDate.getFullYear() - 1);
    historyStartDate.setDate(1);
    historyStartDate = historyStartDate.toISOString().split("T")[0];

    product.orders.forEach((order) => {
      if (order.date >= historyStartDate) {
        let key = order.date.slice(0, 7);
        if (!history.value[key]) {
          history.value[key] = [0, 0];
          history.stock[key] = [0, 0];
        }

        if (order.type === "purchase") {
          history.value[key][0] += order.quantity * order.price;
          history.stock[key][0] += order.quantity;
        } else {
          history.value[key][1] += order.quantity * order.price;
          history.stock[key][1] += order.quantity;
        }
      }
      if (order.type === "purchase") {
        purchasesData.value += order.price * order.quantity;
        purchasesData.quantity += order.quantity;
      } else {
        salesData.value += order.price * order.quantity;
        salesData.quantity += order.quantity;
      }
    });

    purchasesData.avgPrice =
      purchasesData.quantity !== 0
        ? purchasesData.value / purchasesData.quantity
        : 0;
    salesData.avgPrice =
      salesData.quantity !== 0 ? salesData.value / salesData.quantity : 0;

    return {
      purchases: [
        purchasesData.value,
        purchasesData.quantity,
        purchasesData.avgPrice
      ],
      sales: [salesData.value, salesData.quantity, salesData.avgPrice],
      stockHistory: history.stock,
      valueHistory: history.value
    };
  }, [product]);

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%"
      }}
    >
      <Grid container spacing={3} wrap="nowrap">
        <Grid item xs={11}>
          <div>
            <Typography
              variant={theme.direction === "rtl" ? "h6" : "h4"}
              sx={
                theme.direction === "rtl"
                  ? {
                      fontFamily: theme.customVars.urduFont,
                      lineHeight: "2em"
                    }
                  : {}
              }
              noWrap
            >
              {product?.name}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            sx={{ alignSelf: "center" }}
            onClick={handleAddAccountDialogOpen}
          >
            <SettingsIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={3} pt={3}>
        <Grid item lg={4} md={5} xs={12}>
          <ProductData product={product} purchases={purchases} sales={sales} />
        </Grid>
        <Grid item lg={8} md={7} xs={12}>
          <SalePurchaseChart
            stockHistory={stockHistory}
            valueHistory={valueHistory}
          />
        </Grid>
      </Grid>
      <ProductDialog
        open={openProductDialog}
        handleClose={handleProductDialogClose}
        handleSubmit={handleEditProductSubmit}
        origDetails={product}
        handleProductDelete={handleProductDelete}
      />
    </Box>
  );
}

ProductDetailsHeader.propTypes = {
  products: PropTypes.object.isRequired,
  editProduct: PropTypes.func.isRequired,
  handleProductDelete: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  products: state.user.products
});

export default connect(mapStateToProps, { editProduct, deleteProduct })(
  ProductDetailsHeader
);
