import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet";
import Profile from "../components/Settings/Profile";
import PasswordChange from "../components/Settings/PasswordChange";

export default function SettingsPage() {
  return (
    <>
      <Helmet>
        <title>Account | Material Kit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Profile />
            </Grid>
            <Grid item xs={12}>
              <PasswordChange />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
