import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, useTheme } from "@mui/system";
import HkTextField from "../CustomizedMui/HkTextField";
import { CircularProgress, MenuItem, Select, Typography } from "@mui/material";
import HkBotton from "../CustomizedMui/HkButton";
import { Marginer } from "../Marginer/Marginer";
import ResponsiveDialog from "./ResponsiveDialog";
import MuiBackdrop from "../Backdrop/Backdrop";
import AlertDialog from "./AlertDialog";

const useStyles = makeStyles((theme) => ({
  error: theme.customClasses.error,
  progressCircle: theme.customClasses.progressCircle,
  selectField: {
    marginTop: "10px",
    fontSize: "13px",
    minHeight: "25px",
    lineHeight: "200%"
  },
  selectIcon: {
    top: "calc(50% - 6px)",
    left: "0 !important"
  }
}));
function ProductDialog(props) {
  const { open, handleClose, handleSubmit, origDetails, handleProductDelete } =
    props;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const validationSchema = yup.object({
    name: yup.string(t("name")).required("Name is required"),
    unit: yup.string(t("unit")).required("Unit is required"),
    price: yup
      .number()
      .typeError("Must be a number")
      .min(0, "Price must be atleast zero")
      .required("Price is required")
  });
  const formik = useFormik({
    initialValues: {
      name: origDetails?.name ?? "",
      unit: origDetails?.unit ?? "Kilogram",
      price: origDetails?.price ?? "0"
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      handleSubmit(values)
        .then(() => handleDialogClose())
        .catch((err) => {
          setErrors({ general: err });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  useEffect(() => {
    if (origDetails) {
      formik.values.name = origDetails.name;
      formik.values.unit = origDetails.unit;
      formik.values.price = origDetails.price;
    }

    return () => {
      setErrors(null);
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origDetails]);

  const handleDialogClose = () => {
    formik.resetForm();
    setErrors(null);
    setLoading(false);
    handleClose();
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteConfirmationDialog(false);
    handleClose();
  };
  const handleDeleteClick = () => {
    setOpenDeleteConfirmationDialog(true);
  };

  const units = ["Kilogram", "Maund", "Ton", "Bag", "Litre", "Gallon", "Count"];

  const dialogTitle = (
    <Box
      component="span"
      sx={
        theme.direction === "rtl"
          ? { fontFamily: theme.customVars.urduFont }
          : {}
      }
    >
      {origDetails ? t("edit_product") : t("add_product")}
    </Box>
  );

  const dialogContent = (
    <form
      data-testid="add-product-form"
      onSubmit={formik.handleSubmit}
      id="product-form"
    >
      <HkTextField
        data-testid="name"
        disabled={!!origDetails}
        id="name"
        name="name"
        type="text"
        placeholder={t("name")}
        margin="normal"
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        value={formik.values.name}
        onChange={formik.handleChange}
        style={{ textTransform: "capitalize" }}
        fullWidth
        autoFocus
      />

      <HkTextField
        data-testid="price"
        id="price"
        name="price"
        type="number"
        placeholder={t("price")}
        margin="normal"
        error={formik.touched.price && Boolean(formik.errors.price)}
        helperText={formik.touched.price && formik.errors.price}
        value={formik.values.price}
        onChange={formik.handleChange}
        fullWidth
      />

      <Select
        data-testid="unit"
        disabled={!!origDetails}
        id="unit"
        name="unit"
        placeholder={t("unit")}
        variant="standard"
        value={formik.values.unit}
        error={formik.touched.unit && Boolean(formik.errors.unit)}
        onChange={formik.handleChange}
        sx={
          theme.direction === "rtl"
            ? {
                fontFamily: theme.customVars.urduFont,
                lineHeight: "35px"
              }
            : {}
        }
        fullWidth
      >
        {units.map((unit) => (
          <MenuItem
            sx={{
              ...(theme.direction === "rtl" && {
                fontFamily: theme.customVars.urduFont
              })
            }}
            value={unit}
            key={unit}
          >
            {t(unit)}
          </MenuItem>
        ))}
      </Select>

      {errors && (
        <Typography className={classes.error} variant="body2">
          <Box
            component="span"
            sx={
              theme.direction === "rtl"
                ? { fontFamily: theme.customVars.urduFont }
                : {}
            }
          >
            {errors.general}
          </Box>
        </Typography>
      )}
    </form>
  );

  const dialogActions = (
    <>
      <HkBotton
        sx={{
          mr: theme.direction === "ltr" ? "auto" : 0,
          ml: theme.direction === "rtl" ? "auto" : 0,
          display: origDetails?.orders.length !== 0 ? "none" : "block"
        }}
        onClick={handleDeleteClick}
        color="secondary"
        variant="outlined"
        size="small"
      >
        {t("delete")}
      </HkBotton>

      <HkBotton onClick={handleDialogClose} color="secondary">
        {t("cancel")}
      </HkBotton>

      <Marginer direction="horizontal" margin="10px" />
      <HkBotton
        data-testid="product-submit-button"
        color="primary"
        form="product-form"
        type="submit"
        variant="contained"
        disabled={loading}
      >
        {loading && (
          <CircularProgress
            className={classes.progressCircle}
            size={25}
            color="secondary"
          />
        )}
        {t("submit")}
      </HkBotton>
    </>
  );
  return (
    <>
      <ResponsiveDialog
        open={open}
        handleClose={handleDialogClose}
        dialogTitle={dialogTitle}
        dialogContent={dialogContent}
        dialogActions={dialogActions}
      />
      <MuiBackdrop open={loading} />
      <AlertDialog
        open={openDeleteConfirmationDialog}
        handleDialogClose={handleDeleteDialogClose}
        handleDialogSubmit={handleProductDelete}
        goToOnSuccess={"/app/inventory"}
        title={t("delete_product_title")}
        submitButtonText={t("delete_it")}
      >
        {theme.direction === "ltr" ? (
          <>
            <span style={{ color: "red" }}>WARNING! </span> Confirm deletion of
            product.
          </>
        ) : (
          <>
            <span style={{ color: "red" }}>{t("warning")} </span>{" "}
            {t("delete_product_msg")}
          </>
        )}
      </AlertDialog>
    </>
  );
}

ProductDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleProductDelete: PropTypes.func,
  origDetails: PropTypes.object
};

export default ProductDialog;
