import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, useTheme } from "@mui/system";
import HkTextField from "../CustomizedMui/HkTextField";
import {
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import HkButton from "../CustomizedMui/HkButton";
import { Marginer } from "../Marginer/Marginer";
import ResponsiveDialog from "./ResponsiveDialog";
import MuiBackdrop from "../Backdrop/Backdrop";

const useStyles = makeStyles((theme) => ({
  error: theme.customClasses.error,
  progressCircle: theme.customClasses.progressCircle,
  selectField: {
    marginTop: "10px",
    fontSize: "13px",
    minHeight: "25px",
    lineHeight: "200%"
  },
  selectIcon: {
    top: "calc(50% - 6px)",
    left: "0 !important"
  }
}));
function TransactionDialog(props) {
  const { open, handleClose, origTransaction, handleTransactionSubmit } = props;

  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const typeRegExp = /^Credit$|^Debit$/;

  const validationSchema = yup.object({
    date: yup.date().required("Select a date"),
    amount: yup
      .number()
      .typeError("Must be a number")
      .positive("Amount must be atleast zero")
      .required("Amount is required"),
    type: yup
      .string(t("type"))
      .matches(typeRegExp, 'Invalid type. Must be "Credit" or "Debit"')
      .required("Select a type."),
    details: yup.string(t("details"))
  });
  const formik = useFormik({
    initialValues: {
      date: origTransaction?.date ?? new Date().toISOString().split("T")[0],
      amount: origTransaction?.amount ?? "",
      type: origTransaction?.type ?? "Debit",
      details: origTransaction?.details ?? ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      let isEditing = origTransaction !== undefined;
      let transactionData = isEditing
        ? { ...origTransaction, ...values }
        : values;

      handleTransactionSubmit(transactionData, isEditing)
        .then(() => {
          handleDialogClose();
        })
        .catch((err) => {
          setErrors({ general: err });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  useEffect(() => {
    if (origTransaction) {
      formik.values.date = origTransaction.date;
      formik.values.amount = origTransaction.amount;
      formik.values.type = origTransaction.type;
      formik.values.details = origTransaction.details;
    }

    return () => {
      setErrors(null);
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origTransaction]);

  const handleDialogClose = () => {
    formik.resetForm();
    setErrors(null);
    setLoading(false);
    handleClose();
  };

  const dialogTitle = (
    <Box
      component="span"
      sx={
        theme.direction === "rtl"
          ? { fontFamily: theme.customVars.urduFont }
          : {}
      }
    >
      {t("transaction")}
    </Box>
  );
  const dialogContent = (
    <form
      data-testid="trans-form"
      onSubmit={formik.handleSubmit}
      id="transaction-form"
    >
      <HkTextField
        dir="ltr"
        id="date"
        name="date"
        type="date"
        placeholder={t("date")}
        margin="normal"
        error={formik.touched.date && Boolean(formik.errors.date)}
        helperText={formik.touched.date && formik.errors.date}
        value={formik.values.date}
        onChange={formik.handleChange}
        sx={{
          input: {
            fontFamily: theme.typography.body1.fontFamily
          }
        }}
        fullWidth={theme.direction !== "rtl"}
        autoFocus
      />
      <RadioGroup
        row
        label="type"
        id="type"
        name="type"
        value={formik.values.type}
        onChange={formik.handleChange}
        sx={{
          pt: 2,
          legend: { fontFamily: theme.customVars.urduFont },
          span: { fontFamily: theme.customVars.urduFont }
        }}
      >
        <FormLabel component="legend" sx={{ pt: 1.1, px: 0.8 }}>
          {t("transaction_type")}:
        </FormLabel>
        <FormControlLabel
          value="Debit"
          control={<Radio />}
          label={t("Debit")}
          sx={{ fontFamily: theme.customVars.urduFont }}
        />
        <FormControlLabel
          value="Credit"
          control={<Radio />}
          label={t("Credit")}
          componentsProps={{
            style: { fontFamily: theme.customVars.urduFont }
          }}
        />
      </RadioGroup>

      <HkTextField
        id="amount"
        name="amount"
        type="text"
        placeholder={t("amount")}
        margin="normal"
        error={formik.touched.amount && Boolean(formik.errors.amount)}
        helperText={formik.touched.amount && formik.errors.amount}
        value={formik.values.amount}
        onChange={formik.handleChange}
        fullWidth
      />

      <HkTextField
        id="details"
        name="details"
        placeholder={t("details")}
        margin="normal"
        error={formik.touched.details && Boolean(formik.errors.details)}
        helperText={formik.touched.details && formik.errors.details}
        value={formik.values.details}
        onChange={formik.handleChange}
        fullWidth
      />

      {errors && (
        <Typography className={classes.error} variant="body2">
          <Box
            component="span"
            sx={
              theme.direction === "rtl"
                ? { fontFamily: theme.customVars.urduFont }
                : {}
            }
          >
            {errors["general"]}
          </Box>
        </Typography>
      )}
    </form>
  );

  const dialogActions = (
    <>
      <HkButton onClick={handleDialogClose} color="secondary">
        {t("cancel")}
      </HkButton>
      <Marginer direction="horizontal" margin="10px" />
      <HkButton
        data-testid="trans-submit"
        color="primary"
        form="transaction-form"
        type="submit"
        variant="contained"
        disabled={loading}
      >
        {loading && (
          <CircularProgress
            className={classes.progressCircle}
            size={25}
            color="secondary"
          />
        )}
        {t("transaction_submit")}
      </HkButton>
    </>
  );
  return (
    <>
      <ResponsiveDialog
        open={open}
        handleClose={handleDialogClose}
        dialogTitle={dialogTitle}
        dialogContent={dialogContent}
        dialogActions={dialogActions}
      />
      <MuiBackdrop open={loading} />
    </>
  );
}

TransactionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  origTransaction: PropTypes.object,
  handleTransactionSubmit: PropTypes.func.isRequired
};

export default TransactionDialog;
