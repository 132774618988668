import {
  LOADING_UI,
  ADD_ACCOUNT,
  CLEAR_ERRORS,
  SET_ERRORS,
  ADD_TRANSACTION,
  EDIT_TRANSACTION,
  DELETE_TRANSACTIONS,
  EDIT_ACCOUNT,
  SET_USER,
  CLEAR_USER,
  DELETE_ACCOUNT,
  ADD_PRODUCT,
  EDIT_PRODUCT,
  ADD_ORDER,
  EDIT_ORDER,
  DELETE_ORDERS,
  DELETE_PRODUCT
} from '../types';

import { firestore } from '../../firebase/firebase';

export const getUserData = (uid) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: LOADING_UI });

    const userDocumentReference = firestore.collection('users').doc(uid);

    const userData = {};
    let accounts = {};
    let products = {};
    userDocumentReference
      .get()
      .then((doc) => {
        if (!doc.exists) {
          new Error('No user data available.');
        }
        userData.credentials = doc.data();
        userData.credentials.userId = uid;

        return firestore
          .collection('accounts')
          .where('userId', '==', uid)
          .orderBy('name')
          .get();
      })
      .then((docs) => {
        docs.forEach((doc) => {
          let account = doc.data();
          account.transactions = [];
          accounts[account.accountId] = account;
        });

        return firestore
          .collection('transactions')
          .where('userId', '==', uid)
          .get();
      })
      .then((docs) => {
        docs.forEach((doc) => {
          let transactionData = doc.data();
          accounts[transactionData.accountId].transactions.push(
            transactionData
          );
        });
        userData.accounts = accounts;

        return firestore
          .collection('inventory')
          .where('userId', '==', uid)
          .get();
      })
      .then((docs) => {
        docs.forEach((doc) => {
          let product = doc.data();
          product.orders = [];
          products[product.productId] = product;
        });
        userData.products = products;

        return firestore.collection('orders').where('userId', '==', uid).get();
      })
      .then((docs) => {
        docs.forEach((doc) => {
          let order = doc.data();
          products[order.productId].orders.push(order);
        });
        dispatch({ type: SET_USER, payload: userData });
        dispatch({ type: CLEAR_ERRORS });
        resolve();
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SET_ERRORS, payload: err.message });
        reject(err.message);
      });
  });
};

export const setAccount = (accountData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    //Capitalize first letter
    let name = accountData.name.trim();
    name = name[0].toUpperCase() + name.slice(1);
    accountData.name = name;

    let isEditing = accountData.hasOwnProperty('accountId');
    let accountRef;
    if (isEditing) {
      accountRef = firestore.collection('accounts').doc(accountData.accountId);
      accountRef
        .set(accountData, { merge: true })
        .then(() => {
          dispatch({ type: EDIT_ACCOUNT, payload: accountData });
          resolve(accountData);
        })
        .catch((err) => {
          console.error(err);
          reject(err.message);
        });
    } else {
      //if new account, check for uniqueness of name
      firestore
        .collection('accounts')
        .where('name', '==', name)
        .where('userId', '==', accountData.userId)
        .get()
        .then((docs) => {
          if (!docs.empty) {
            throw new Error(`Account for "${name}" already exists.`);
          } else {
            accountRef = firestore.collection('accounts').doc();
            accountData.accountId = accountRef.id;
            return accountRef.set(accountData);
          }
        })
        .then(() => {
          dispatch({ type: ADD_ACCOUNT, payload: accountData });
          resolve(accountData);
        })
        .catch((err) => {
          console.error(err);
          reject(err.message);
        });
    }
  });
};

export const deleteAccount = (accountId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let accountRef = firestore.collection('accounts').doc(accountId);
    accountRef
      .delete()
      .then(() => {
        dispatch({ type: DELETE_ACCOUNT, payload: accountId });
        resolve();
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export const setTransaction = (transactionData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let isEditing = transactionData.hasOwnProperty('transactionId');

    let transactionRef = isEditing
      ? firestore.collection('transactions').doc(transactionData.transactionId)
      : firestore.collection('transactions').doc();

    if (!isEditing) {
      transactionData.transactionId = transactionRef.id;
    }
    let amount = Number(transactionData.amount);
    transactionData.amount = amount;
    transactionRef
      .set(transactionData, { merge: true })
      .then(() => {
        let type = isEditing ? EDIT_TRANSACTION : ADD_TRANSACTION;

        dispatch({ type, payload: transactionData });
        resolve(transactionData);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

export const deleteTransactions =
  (transactionsToDelete, accountId) => (dispatch) => {
    return new Promise((resolve, reject) => {
      try {
        let batchesArray = [];
        batchesArray.push(firestore.batch());
        let currentBatch = 0;
        let count = 0;
        let transactionRef;
        transactionsToDelete.forEach((id) => {
          transactionRef = firestore.collection('transactions').doc(id);
          batchesArray[currentBatch].delete(transactionRef);
          count++;

          if (count === 499) {
            batchesArray.push(firestore.batch());
            currentBatch++;
            count = 0;
          }
        });
        batchesArray.forEach(async (batch) => await batch.commit());

        dispatch({
          type: DELETE_TRANSACTIONS,
          payload: { transactionsToDelete, accountId }
        });

        resolve();
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };

export const addProduct = (productData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let isEditing = productData.hasOwnProperty('productId');

    var productRef;
    var accountData;
    if (isEditing) {
      productRef = firestore.collection('inventory').doc(productData.productId);
      productRef
        .set(productData, { merge: true })
        .then(() => {
          dispatch({ type: EDIT_PRODUCT, payload: productData });
          resolve(productData);
        })
        .catch((err) => {
          console.error(err);
          reject(err.message);
        });
    } else {
      //if new product being added
      //Capitalize first letter
      let name = productData.name.trim();
      productData.name = name[0].toUpperCase() + name.slice(1);
      //if new product, check for uniqueness of name
      firestore
        .collection('inventory')
        .where('name', '==', productData.name)
        .where('userId', '==', productData.userId)
        .get()
        .then((docs) => {
          if (!docs.empty) {
            reject(`Product for "${name}" already exists.`);
          } else {
            //check to see no account with product's name exists
            firestore
              .collection('accounts')
              .where('name', '==', name)
              .where('userId', '==', productData.userId)
              .get()
              .then((docs) => {
                if (!docs.empty) {
                  throw new Error(`Account for "${name}" already exists.`);
                } else {
                  let batch = firestore.batch();
                  accountData = {
                    name: name,
                    category: 'Inventory',
                    createdAt: new Date().toISOString(),
                    email: '',
                    phoneNum: '',
                    userId: productData.userId
                  };
                  let accountRef = firestore.collection('accounts').doc();
                  accountData.accountId = accountRef.id;

                  productRef = firestore.collection('inventory').doc();
                  productData.productId = productRef.id;
                  productData.inventoryAccountId = accountRef.id;
                  productData.quantityOnHand = 0;
                  accountData.productId = productRef.id;
                  productData.priceUpdatedAt = new Date().toISOString();

                  batch.set(accountRef, accountData);
                  batch.set(productRef, productData);
                  return batch.commit();
                }
              })
              .then(() => {
                dispatch({ type: ADD_ACCOUNT, payload: accountData });
                dispatch({ type: ADD_PRODUCT, payload: productData });
                resolve(productData);
              })
              .catch((err) => {
                console.error(err);
                reject(err.message);
              });
          }
        });
    }
  });
};

export const editProduct = (productData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    //delete name and unit fields from data, as they cannot be edited
    delete productData.name;
    delete productData.unit;

    var productRef = firestore
      .collection('inventory')
      .doc(productData.productId);
    productRef
      .set(productData, { merge: true })
      .then(() => {
        dispatch({ type: EDIT_PRODUCT, payload: productData });
        resolve(productData);
      })
      .catch((err) => {
        console.error(err);
        reject(err.message);
      });
  });
};

export const deleteProduct = (productId, accountId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    let batch = firestore.batch();
    let actionPayload = {};
    let accountRef = firestore.collection('accounts').doc(accountId);
    let productRef = firestore.collection('inventory').doc(productId);
    firestore
      .collection('transactions')
      .where('accountId', '==', accountId)
      .get()
      .then((docs) => {
        if (docs.empty) {
          batch.delete(accountRef);
          actionPayload.accountId = accountId;
        }
        batch.delete(productRef);
        actionPayload.productId = productId;

        return batch.commit();
      })
      .then(() => {
        dispatch({ type: DELETE_PRODUCT, payload: actionPayload });
        resolve();
      })
      .catch((err) => {
        console.error(err);
        reject(err.message);
      });
  });
};

export const addOrder =
  (
    orderData,
    updatedProductData,
    inventoryTransactionData,
    paymentTransactionData
  ) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      let orderRef = firestore.collection('orders').doc();
      let productRef = firestore
        .collection('inventory')
        .doc(orderData.productId);
      let inventoryTransactionRef = firestore.collection('transactions').doc();

      let paymentTransactionRef = paymentTransactionData
        ? firestore.collection('transactions').doc()
        : null;

      let batch = firestore.batch();
      orderData.orderId = orderRef.id;
      orderData.inventoryTransactionId = inventoryTransactionRef.id;
      orderData.paymentTransactionId = paymentTransactionRef
        ? paymentTransactionRef.id
        : 'cash';

      inventoryTransactionData.orderId = orderRef.id;
      inventoryTransactionData.transactionId = inventoryTransactionRef.id;
      if (paymentTransactionData) {
        paymentTransactionData.orderId = orderRef.id;
        paymentTransactionData.transactionId = paymentTransactionRef.id;
      }

      batch.set(orderRef, orderData);
      batch.set(productRef, updatedProductData, { merge: true });
      batch.set(inventoryTransactionRef, inventoryTransactionData);
      paymentTransactionRef &&
        batch.set(paymentTransactionRef, paymentTransactionData);

      batch
        .commit()
        .then(() => {
          dispatch({
            type: ADD_ORDER,
            payload: {
              orderData,
              updatedProductData,
              inventoryTransactionData,
              paymentTransactionData
            }
          });
          resolve('Success');
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  };

export const editOrder =
  ({
    newOrderData: orderData,
    updatedProductData,
    originalPaymentTransaction,
    newPaymentTransaction,
    newInventoryTransaction
  }) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      let orderRef = firestore.collection('orders').doc(orderData.orderId);
      let productRef = firestore
        .collection('inventory')
        .doc(orderData.productId);

      let inventoryTransactionRef = firestore
        .collection('transactions')
        .doc(newInventoryTransaction.transactionId);

      let batch = firestore.batch();

      batch.set(productRef, updatedProductData, { merge: true });
      batch.set(inventoryTransactionRef, newInventoryTransaction, {
        merge: true
      });

      let paymentTransactionRef;
      if (originalPaymentTransaction) {
        if (originalPaymentTransaction.delete) {
          orderData.paymentAccountId = 'cash';
          orderData.paymentTransactionId = 'cash';
          paymentTransactionRef = firestore
            .collection('transactions')
            .doc(originalPaymentTransaction.transactionId);
          batch.delete(paymentTransactionRef);
        } else {
          paymentTransactionRef = firestore
            .collection('transactions')
            .doc(originalPaymentTransaction.transactionId);
          batch.set(paymentTransactionRef, originalPaymentTransaction, {
            merge: true
          });
        }
      }
      if (newPaymentTransaction) {
        paymentTransactionRef = firestore.collection('transactions').doc();
        newPaymentTransaction.transactionId = paymentTransactionRef.id;
        orderData.paymentTransactionId = paymentTransactionRef.id;
        orderData.paymentAccountId = newPaymentTransaction.accountId;

        batch.set(paymentTransactionRef, newPaymentTransaction);
      }

      batch.set(orderRef, orderData, { merge: true });

      batch
        .commit()
        .then(() => {
          dispatch({
            type: EDIT_ORDER,
            payload: {
              orderData,
              updatedProductData,
              originalPaymentTransaction,
              newPaymentTransaction,
              newInventoryTransaction
            }
          });
          resolve();
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  };

export const deleteOrders =
  (inventoryToUpdate, accountsToUpdate) => (dispatch) => {
    return new Promise((resolve, reject) => {
      try {
        let batchesArray = [];
        batchesArray.push(firestore.batch());
        let currentBatch = 0;
        let count = 0;

        //add deleted orders to batch
        let orderRef;
        inventoryToUpdate.ordersToDelete.forEach((id) => {
          orderRef = firestore.collection('orders').doc(id);
          batchesArray[currentBatch].delete(orderRef);
          count++;

          if (count === 497) {
            batchesArray.push(firestore.batch());
            currentBatch++;
            count = 0;
          }
        });

        //add deleted transactions to batch
        let transactionsToDelete = [];
        Object.values(accountsToUpdate).forEach((acc) => {
          transactionsToDelete.push(...acc.transactionsToDelete);
        });

        let transactionRef;
        transactionsToDelete.forEach((id) => {
          transactionRef = firestore.collection('transactions').doc(id);
          batchesArray[currentBatch].delete(transactionRef);
          count++;

          if (count === 498) {
            batchesArray.push(firestore.batch());
            currentBatch++;
            count = 0;
          }
        });

        let productRef = firestore
          .collection('inventory')
          .doc(inventoryToUpdate.productId);
        batchesArray[currentBatch].set(
          productRef,
          { quantityOnHand: inventoryToUpdate.quantityOnHand },
          { merge: true }
        );

        batchesArray.forEach(async (batch) => await batch.commit());

        dispatch({
          type: DELETE_ORDERS,
          payload: {
            inventoryToUpdate,
            accountsToUpdate
          }
        });

        resolve();
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };

export const clearUserData = () => (dispatch) => {
  dispatch({ type: CLEAR_USER });
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// export const clearAccount = () => (dispatch) => {
//   dispatch({ type: UNSET_ACCOUNT });
// };

// //************HELPERS*******************/

// const getAccount = (userData, accountId) =>
//   userData.accounts.filter((account) => account.accountId === accountId)[0];

// const replaceTransaction = (
//   transactionList,
//   transactionId,
//   newTransaction = null
// ) => {
//   const transIndex = transactionList.findIndex(
//     (transaction) => transaction.transactionId === transactionId
//   );
//   if (transIndex >= 0) {
//     if (newTransaction) {
//       transactionList.splice(transIndex, 1, newTransaction);
//     } else {
//       transactionList.splice(transIndex, 1);
//     }
//   }
//   return transactionList;
// };
