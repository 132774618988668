import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/system";

export default function ProductData({ product, purchases, sales, ...rest }) {
  const [dataToDisplay, setDataToDisplay] = useState("purchases");
  const value = product.quantityOnHand * product.price;

  const { t } = useTranslation();
  const theme = useTheme();

  const bulletColors = [
    "rgb(47, 62, 177)",
    "rgb(12, 124, 213)",
    "rgb(123, 198, 126)"
  ];
  const labels = [
    `${t("value")} (Rs)`,
    `${t("quantity")} (${t(product.unit)})`,
    `${t("avg_price")} (Rs)`
  ];

  return (
    <Card
      data-testid="product-data"
      sx={{
        padding: "8px"
      }}
    >
      <CardContent>
        <Typography
          variant={theme.direction === "rtl" ? "subtitle2" : "overline"}
          gutterBottom={theme.direction === "rtl"}
          sx={{
            fontWeight: "700",
            color: "rgb(101, 116, 139)",
            lineHeight: 2.5,
            fontFamily:
              theme.direction === "rtl"
                ? theme.customVars.urduFont
                : theme.typography.fontFamily
          }}
        >
          {t("current_value")}
        </Typography>
        <Typography
          data-testid="current-value"
          variant="h4"
          sx={{
            fontWeight: 700,
            lineHeight: "1.375",
            fontSize: { xs: "1.5rem", sm: "1.8rem" }
          }}
        >
          {isNaN(value) ? "" : value}
        </Typography>
        <Typography
          data-testid="product-quantity-price"
          variant="body2"
          color="textSecondary"
        >
          {`${product.quantityOnHand} ${t(product.unit)} @ Rs.${product.price}`}
        </Typography>
        <Divider sx={{ margin: "16px 0px", flexShrink: 0 }} />
        <Typography
          variant="overline"
          color="textSecondary"
          sx={{
            fontWeight: "400",
            fontFamily: theme.customVars.urduFont
          }}
        >
          {t("one_year")}
        </Typography>
        <List
          sx={{
            padding: "8px 0px 0px"
          }}
        >
          {(dataToDisplay === "purchases" ? purchases : sales).map(
            (data, i) => (
              <ListItem key={`${product.productId}-${i}`} sx={{ px: 0 }}>
                <ListItemText>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <Box
                        sx={{
                          border: `3px solid ${bulletColors[i]}`,
                          borderRadius: "50%",
                          height: "16px",
                          mr: theme.direction === "ltr" ? 1 : 0,
                          ml: theme.direction === "ltr" ? 0 : 1,
                          width: "16px"
                        }}
                      />
                      <Typography
                        variant="subtitle2"
                        sx={{ fontFamily: theme.customVars.urduFont }}
                      >
                        {labels[i]}
                      </Typography>
                    </Box>
                    <Typography variant="subtitle2">
                      {Math.round(data).toLocaleString()}
                    </Typography>
                  </Box>
                </ListItemText>
              </ListItem>
            )
          )}
        </List>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 2
          }}
        >
          <Button
            variant="text"
            sx={{
              fontFamily: theme.customVars.urduFont,
              ...(dataToDisplay === "purchases" && {
                borderBottom: "2px solid",
                borderRadius: "0%"
              })
            }}
            onClick={() => setDataToDisplay("purchases")}
          >
            {t("purchase")}
          </Button>
          <Button
            variant="text"
            sx={{
              fontFamily: theme.customVars.urduFont,
              ...(dataToDisplay === "sales" && {
                borderBottom: "2px solid",
                borderRadius: "0%"
              })
            }}
            onClick={() => setDataToDisplay("sales")}
          >
            {t("sale")}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
