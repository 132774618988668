import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Box } from '@mui/system';
import { Paper, TableContainer } from '@mui/material';
import TransactionsListToolbar from './TransactionsListToolbar';
import HkTablePaginator from '../CustomizedMui/HkTablePaginator';
import TransactionDialog from '../Dialogs/TransactionDialog';
import {
  setTransaction,
  deleteTransactions
} from '../../redux/actions/dataActions';
import TransactionsTable from './TransactionsTable';
import produce from 'immer';
/************************************************************ */

function TransactionsList({
  transactions,
  accountId,
  userId,
  setTransaction,
  deleteTransactions
}) {
  const [transactionsList, setTransactionsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [clickedTransaction, setClickedTransaction] = useState(undefined);
  const [openTransactionDialog, setOpenTransactionDialog] = useState(false);

  //********************* useEffects ********************* */

  useEffect(() => {
    if (transactions) {
      let list = transactions.map((t) =>
        produce(t, (draft) => {
          draft.debit = t.type === 'Debit' ? t.amount : 0;
          draft.credit = t.type === 'Credit' ? t.amount : 0;
        })
      );
      setTransactionsList(list);
      setRowsPerPage(list.length);
    }
    return () => {
      setTransactionsList([]);
    };
  }, [transactions]);

  useEffect(() => {
    if (clickedTransaction) {
      handleTransactionDialogOpen();
    } else {
      handleTransactionDialogClose();
    }
  }, [clickedTransaction]);

  //********************* handler Functions **********************/
  const handleSelectAllClick = (event) => {
    if (selected.length > 0) {
      setSelected([]);
      return;
    }
    if (event.target.checked) {
      //select all transactions except the ones associated with orders
      console.log('checked');
      const newSelecteds = transactionsList
        .filter((t) => !t.hasOwnProperty('orderId'))
        .map((t) => t.transactionId);
      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  };

  const handleSelectClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    //only allow selection if the transaction is not associated with an inventory order
    if (
      !transactionsList
        .find((t) => t.transactionId === id)
        .hasOwnProperty('orderId')
    ) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    }
    setSelected(newSelected);
  };

  const handleRowClick = (event, id) => {
    let t = transactionsList.find(({ transactionId }) => transactionId === id);
    //only allow editing transaction if there is no order associated with it
    if (!t.hasOwnProperty('orderId')) {
      setClickedTransaction(t);
    } else {
      alert(
        'There is an inventory associated with this transaction. Not allowed to edit it.'
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTransactionDialogOpen = () => {
    setOpenTransactionDialog(true);
  };

  const handleTransactionDialogClose = () => {
    setClickedTransaction(undefined);
    setOpenTransactionDialog(false);
  };

  const handleTransactionSubmit = (transactionData) => {
    //if editing existing transaction
    if (clickedTransaction) {
      transactionData.editedAt = new Date().toISOString();
      delete transactionData.debit;
      delete transactionData.credit;
    } else {
      //if new transaction
      transactionData.accountId = accountId;
      transactionData.userId = userId;
      transactionData.createdAt = new Date().toISOString();
    }
    let amount = Number(transactionData.amount);
    transactionData.amount = amount;
    return setTransaction(transactionData);
  };

  const handleTransactionsDelete = () => {
    return new Promise((resolve, reject) => {
      deleteTransactions(selected, accountId)
        .then(() => {
          setSelected([]);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  //********************* render ********************* */

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TransactionsListToolbar
            numSelected={selected.length}
            handleTransactionSubmit={handleTransactionSubmit}
            handleTransactionsDelete={handleTransactionsDelete}
          />

          <TableContainer>
            <TransactionsTable
              transactions={transactionsList}
              selected={selected}
              handleSelectAllClick={handleSelectAllClick}
              handleSelectClick={handleSelectClick}
              handleRowClick={handleRowClick}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </TableContainer>
          <HkTablePaginator
            count={transactionsList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <TransactionDialog
        open={openTransactionDialog}
        handleClose={handleTransactionDialogClose}
        origTransaction={clickedTransaction}
        handleTransactionSubmit={handleTransactionSubmit}
      />
    </>
  );
}

TransactionsList.propTypes = {
  transactions: PropTypes.array.isRequired,
  accountId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  setTransaction: PropTypes.func.isRequired,
  deleteTransactions: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setTransaction, deleteTransactions })(
  TransactionsList
);
