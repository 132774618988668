import { Container } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet";
import ProductsList from "../components/Inventory/ProductsList";

export default function InventoryPage() {
  return (
    <>
      <Helmet>
        <title>Inventory | HisaabKitaab</title>
      </Helmet>
      <Box sx={{ minHeight: "100%", py: 3 }}>
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <ProductsList />
          </Box>
        </Container>
      </Box>
    </>
  );
}
