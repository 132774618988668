import React from "react";
import Button from "@mui/material/Button";

//Mui
import { useTheme } from "@mui/material/styles";

export default function HkBotton(props) {
  const { sx: sxProp, ...rest } = props;
  const theme = useTheme();

  return (
    <Button
      {...rest}
      sx={
        theme.direction === "rtl"
          ? {
              ...sxProp,
              fontFamily: theme.customVars.urduFont
            }
          : { ...sxProp }
      }
    >
      {props.children}
    </Button>
  );
}
