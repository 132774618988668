/* eslint-disable no-unused-vars */
import React from "react";
import authentication from "../../firebase/authentication";
import useInputState from "../../hooks/useInputState";
import { TextField, Typography } from "@mui/material";
import {
  FormContainer,
  InputsContainer,
  ActionsContainer,
  SubmitButton,
  MutedText,
  BoldLink
} from "./common";
import { Marginer } from "../Marginer/Marginer";

export default function SignupForm({ playAnimation, activate }) {
  const [name, setName, resetName] = useInputState("");
  const [email, setEmail, resetEmail] = useInputState("");
  const [organization, setOrganization, resetOrganization] = useInputState("");
  const [password, setPassword, resetPassword] = useInputState("");
  const [confirmPassword, setConfirmPassword, resetConfirmPassword] =
    useInputState("");

  const switchToLogin = () => {
    playAnimation();
    setTimeout(() => {
      activate("login");
    }, 700);
  };

  const handleSubtmit = (e) => {
    e.preventDefault();
    const newUser = {
      name,
      email,
      organization,
      password,
      confirmPassword
    };

    authentication
      .signUp(newUser)
      .then((value) => {
        resetName();
        resetEmail();
        resetOrganization();
        resetPassword();
        resetConfirmPassword();
        console.log(value);
      })
      .catch((err) => console.error(err));
  };
  return (
    <FormContainer
      component="form"
      id="signup-form"
      noValidate
      autoComplete="off"
    >
      <Marginer direction="vertical" margin="20px" />
      <Typography variant="subtitle2" color="textSecondary" align="center">
        This app is still undergoing beta testing. Please contact
        hisaabkitaabapp@gmail.com for further information.
      </Typography>
      {/* <InputsContainer>
        <TextField
          size="small"
          id="signup-name"
          type="text"
          label="Full Name"
          value={name}
          onChange={setName}
        />
        <TextField
          size="small"
          id="signup-organization"
          type="text"
          label="Organization"
          value={organization}
          onChange={setOrganization}
        />
        <TextField
          size="small"
          id="signup-email"
          type="email"
          label="Email"
          value={email}
          onChange={setEmail}
        />
        <TextField
          size="small"
          id="signup-password"
          type="password"
          label="Password"
          value={password}
          onChange={setPassword}
        />
        <TextField
          size="small"
          id="signup-confirm-password"
          type="password"
          label="Confirm Password"
          value={confirmPassword}
          onChange={setConfirmPassword}
        />
      </InputsContainer>

      <ActionsContainer>
        <SubmitButton
          type="submit"
          variant="contained"
          fullWidth
          onClick={handleSubtmit}
        >
          Sign up
        </SubmitButton>
        <MutedText>
          Already have an Account?{" "}
          <BoldLink onClick={switchToLogin}> Login</BoldLink>
        </MutedText>
      </ActionsContainer> */}
      <MutedText>
        Already have an Account?{" "}
        <BoldLink onClick={switchToLogin}> Login</BoldLink>
      </MutedText>
    </FormContainer>
  );
}
