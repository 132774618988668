import firebase, { auth, firestore } from "./firebase";

const authentication = {};

authentication.signUp = (fields) => {
  return new Promise((resolve, reject) => {
    if (!fields) {
      reject(new Error("No fields"));

      return;
    }

    const name = fields.name;
    const organization = fields.organization;
    const email = fields.email;
    const password = fields.password;

    if (!name || !organization || !email || !password) {
      reject(new Error("No name, organization, e-mail address, or password"));

      return;
    }

    if (auth.currentUser) {
      reject(new Error("There is already a user logged in."));

      return;
    }

    auth
      .createUserWithEmailAndPassword(email, password)
      .then((value) => {
        const user = value.user;

        if (!user) {
          reject(new Error("No user"));

          return;
        }

        const uid = user.uid;

        if (!uid) {
          reject(new Error("No UID"));

          return;
        }

        const userDocumentReference = firestore.collection("users").doc(uid);

        userDocumentReference
          .set({
            name,
            organization,
            email,
            createdAt: new Date().toISOString()
          })
          .then((value) => {
            resolve(value);
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.signUpWithEmailAddressAndPassword = (emailAddress, password) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !password) {
      reject(new Error("No e-mail address or password"));

      return;
    }

    if (auth.currentUser) {
      reject(new Error("No current user"));

      return;
    }

    auth
      .createUserWithEmailAndPassword(emailAddress, password)
      .then((value) => {
        const user = value.user;

        if (!user) {
          reject(new Error("No user"));

          return;
        }

        const uid = user.uid;

        if (!uid) {
          reject(new Error("No UID"));

          return;
        }

        const userDocumentReference = firestore.collection("users").doc(uid);

        userDocumentReference
          .set({}, { merge: true })
          .then((value) => {
            resolve(value);
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.logIn = (emailAddress, password) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !password) {
      reject(new Error("No e-mail address or password"));

      return;
    }

    if (auth.currentUser) {
      reject(new Error("There is already a user logged in."));
      return;
    }

    auth
      .signInWithEmailAndPassword(emailAddress, password)
      .then((value) => {
        const user = value.user;

        if (!user) {
          reject(new Error("No user"));

          return;
        }

        const uid = user.uid;

        if (!uid) {
          reject(new Error("No UID"));

          return;
        }

        const userDocumentReference = firestore.collection("users").doc(uid);

        userDocumentReference
          .get({ source: "server" })
          .then((value) => {
            if (value.exists) {
              resolve(user);
            } else {
              userDocumentReference
                .set({}, { merge: true })
                .then((value) => {
                  resolve(user);
                })
                .catch((reason) => {
                  reject(reason);
                });
            }
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.sendLogInLinkToEmail = (emailAddress) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject(new Error("No e-mail address"));

      return;
    }

    if (auth.currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const actionCodeSettings = {
      url: process.env.REACT_APP_HOMEPAGE,
      handleCodeInApp: true
    };

    auth
      .sendSignInLinkToEmail(emailAddress, actionCodeSettings)
      .then((value) => {
        localStorage.setItem("emailAddress", emailAddress);

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.logInWithEmailLink = (emailAddress, emailLink) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress || !emailLink) {
      reject(new Error("No e-mail address or e-mail link"));

      return;
    }

    if (auth.currentUser) {
      reject(new Error("No current user"));

      return;
    }

    auth
      .signInWithEmailLink(emailAddress, emailLink)
      .then((value) => {
        localStorage.removeItem("emailAddress");

        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.logOut = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    auth
      .signOut()
      .then((value) => {
        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.resetPassword = (emailAddress) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject(new Error("No e-mail address"));

      return;
    }

    if (auth.currentUser) {
      reject(new Error("No current user"));

      return;
    }

    auth
      .sendPasswordResetEmail(emailAddress)
      .then((value) => {
        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changeFirstName = (firstName) => {
  return new Promise((resolve, reject) => {
    if (!firstName) {
      reject(new Error("No first name"));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No UID"));

      return;
    }

    const userDocumentReference = firestore.collection("users").doc(uid);

    userDocumentReference
      .update({
        firstName: firstName
      })
      .then((value) => {
        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changeEmailAddress = (emailAddress) => {
  return new Promise((resolve, reject) => {
    if (!emailAddress) {
      reject(new Error("No e-mail address"));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No UID"));

      return;
    }

    currentUser
      .updateEmail(emailAddress)
      .then((value) => {
        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.changePassword = (password) => {
  return new Promise((resolve, reject) => {
    if (!password) {
      reject(new Error("No password"));

      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No UID"));

      return;
    }

    currentUser
      .updatePassword(password)
      .then((value) => {
        const userDocumentReference = firestore.collection("users").doc(uid);

        userDocumentReference
          .update({
            lastPasswordChange: firebase.firestore.FieldValue.serverTimestamp()
          })
          .then((value) => {
            resolve(value);
          })
          .catch((reason) => {
            reject(reason);
          });
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.verifyEmailAddress = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    currentUser
      .sendEmailVerification()
      .then((value) => {
        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.deleteAccount = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    currentUser
      .delete()
      .then((value) => {
        resolve(value);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.getRoles = () => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));

      return;
    }

    currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        resolve(idTokenResult.claims.roles);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.isAdmin = () => {
  return new Promise((resolve, reject) => {
    authentication
      .getRoles()
      .then((value) => {
        resolve(value.includes("admin"));
      })
      .catch((reason) => {
        reject(reason);
      });
  });
};

authentication.getName = (fields) => {
  if (!fields) {
    return null;
  }

  const firstName = fields.firstName;
  const username = fields.username;
  const displayName = fields.displayName;
  const lastName = fields.lastName;

  if (firstName) {
    return firstName;
  }

  if (username) {
    return username;
  }

  if (displayName) {
    return displayName;
  }

  if (lastName) {
    return lastName;
  }

  return null;
};

authentication.getProfileCompletion = (fields) => {
  if (!fields) {
    return null;
  }

  fields = [
    fields.photoURL,
    fields.firstName,
    fields.lastName,
    fields.username,
    fields.email,
    fields.email && fields.emailVerified
  ];

  if (!fields) {
    return null;
  }

  let profileCompletion = 0;

  fields.forEach((field) => {
    if (field) {
      profileCompletion += 100 / fields.length;
    }
  });

  return Math.floor(profileCompletion);
};

export default authentication;
