import {
  LOADING_DATA,
  SET_ACCOUNT,
  UPDATE_ACCOUNT_DETAILS,
  SETTLE_ACCOUNT,
  UNSET_ACCOUNT
} from "../types";

const initialState = {
  account: {},
  loading: false
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true
      };

    case SET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
        loading: false
      };

    case UPDATE_ACCOUNT_DETAILS:
      return {
        ...state,
        account: {
          ...state.account,
          accountDetails: {
            ...state.account.accountDetails,
            name: action.payload.name,
            email: action.payload.email,
            phoneNum: action.payload.phoneNum,
            category: action.payload.category
          }
        },
        loading: false
      };

    // case ADD_TRANSACTION:
    //   return {
    //     ...state,
    //     account: {
    //       ...state.account,
    //       transactions: [
    //         action.payload.transaction,
    //         ...state.account.transactions
    //       ],
    //       accountDetails: {
    //         ...state.account.accountDetails,
    //         totalCredits: action.payload.totalCredits,
    //         totalDebits: action.payload.totalDebits
    //       }
    //     }
    //   };

    // case EDIT_TRANSACTION:
    //   return {
    //     ...state,
    //     account: {
    //       ...state.account,
    //       transactions: state.account.transactions.map((t) => {
    //         return t.transactionId === action.payload.transaction.transactionId
    //           ? action.payload.transaction
    //           : t;
    //       }),
    //       accountDetails: {
    //         ...state.account.accountDetails,
    //         totalCredits: action.payload.account.totalCredits,
    //         totalDebits: action.payload.account.totalDebits
    //       }
    //     }
    //   };

    // case DELETE_TRANSACTION:
    //   let deleteIndex = state.account.transactions.findIndex(
    //     (t) => t.transactionId === action.payload.transactionId
    //   );
    //   state.account.transactions.splice(deleteIndex, 1);
    //   return {
    //     ...state,
    //     account: {
    //       ...state.account,
    //       transactions: [...state.account.transactions],
    //       accountDetails: {
    //         ...state.account.accountDetails,
    //         totalCredits: action.payload.account.totalCredits,
    //         totalDebits: action.payload.account.totalDebits
    //       }
    //     }
    //   };

    case SETTLE_ACCOUNT:
      return {
        ...state,
        account: {
          ...state.account,
          transactions: action.payload.transactions,
          accountDetails: {
            ...state.account.accountDetails,
            totalCredits: action.payload.updatedAccountData.totalCredits,
            totalDebits: action.payload.updatedAccountData.totalDebits
          }
        }
      };

    case UNSET_ACCOUNT:
      return {
        account: {},
        loading: false
      };

    default:
      return state;
  }
};

export default dataReducer;
