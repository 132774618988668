import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Box } from '@mui/system';
import { Paper, TableContainer } from '@mui/material';
import OrdersListToolbar from './OrdersListToolbar';
import OrdersTable from './OrdersTable';
import HkTablePaginator from '../CustomizedMui/HkTablePaginator';
import OrderDialog from '../Dialogs/OrderDialog';
import {
  addOrder,
  editOrder,
  deleteOrders
} from '../../redux/actions/dataActions';
import produce from 'immer';

function OrdersList(props) {
  const {
    user,
    product: productProp,
    addOrder,
    editOrder,
    deleteOrders
  } = props;

  //***********************    state    ****************************** */
  const [ordersList, setOrdersList] = useState([]);
  const [product, setProduct] = useState({});
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [openOrderDialog, setOpenOrderDialog] = useState(false);
  const [clickedOrder, setClickedOrder] = useState(undefined);

  //********************    useEffects    *******************************/

  useEffect(() => {
    if (productProp) {
      setProduct(productProp);
      let list = productProp.orders.map((ord) =>
        produce(ord, (draft) => {
          draft.amount = ord.price * ord.quantity;
          draft.paymentAccount =
            ord.paymentAccountId === 'cash'
              ? 'cash'
              : user.accounts[ord.paymentAccountId].name;
          return draft;
        })
      );

      setOrdersList(list);
    }

    return () => {
      setProduct({});
      setOrdersList([]);
    };
  }, [productProp, user.accounts]);

  useEffect(() => {
    if (clickedOrder) {
      handleOrderDialogOpen();
    } else {
      handleOrderDialogClose();
    }
  }, [clickedOrder]);

  //********************    handle functions    *******************************/

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = ordersList.map((ord) => ord.orderId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRowClick = (event, id) => {
    setClickedOrder(ordersList.find(({ orderId }) => orderId === id));
  };

  const handleOrderDialogOpen = () => {
    setOpenOrderDialog(true);
  };

  const handleOrderDialogClose = () => {
    setClickedOrder(undefined);
    setOpenOrderDialog(false);
  };

  const handleAddOrderSubmit = (orderData) => {
    let time = new Date().toISOString();

    let updatedProductData = {
      quantityOnHand: product.quantityOnHand,
      price: product.price,
      priceUpdatedAt: product.priceUpdatedAt
    };

    let paymentTransactionData;
    let inventoryTransactionData;

    let orderQty = Number(orderData.quantity);
    let orderPrice = Number(orderData.price);
    let amount = orderQty * orderPrice;
    orderData.quantity = orderQty;
    orderData.price = orderPrice;

    //create order data
    orderData.productId = product.productId;
    orderData.userId = user.credentials.userId;
    orderData.inventoryAccountId = product.inventoryAccountId;
    orderData.createdAt = time;

    //if not a cash payment, create paymentTransaction, and updatedPaymentAccountData
    if (orderData.paymentAccountId !== 'cash') {
      paymentTransactionData = {
        type: orderData.type === 'purchase' ? 'Credit' : 'Debit',
        amount: amount,
        createdAt: time,
        date: orderData.date,
        details: `${orderData.type} ${orderQty} ${product.unit} of ${product.name}`,
        userId: user.credentials.userId,
        accountId: orderData.paymentAccountId
      };
    }

    inventoryTransactionData = {
      type: orderData.type === 'purchase' ? 'Debit' : 'Credit',
      amount: amount,
      createdAt: time,
      date: orderData.date,
      details: `${orderData.type} ${orderQty} ${product.unit} of ${product.name}`,
      userId: user.credentials.userId,
      accountId: product.inventoryAccountId
    };

    //updated product data
    if (orderData.type === 'purchase') {
      updatedProductData.quantityOnHand += orderQty;
    } else {
      updatedProductData.quantityOnHand -= orderQty;
    }

    if (orderData.date >= updatedProductData.priceUpdatedAt.split('T')[0]) {
      updatedProductData.priceUpdatedAt = time;
      updatedProductData.price = orderPrice;
    }

    return addOrder(
      orderData,
      updatedProductData,
      inventoryTransactionData,
      paymentTransactionData
    );
  };

  const handleEditOrderSubmit = (newOrderData) => {
    let originalOrder = clickedOrder;
    let newQty = Number(newOrderData.quantity);
    let newPrice = Number(newOrderData.price);
    let time = new Date().toISOString();

    newOrderData.quantity = newQty;
    newOrderData.price = newPrice;
    newOrderData.orderId = originalOrder.orderId;
    newOrderData.productId = originalOrder.productId;
    newOrderData.inventoryAccountId = originalOrder.inventoryAccountId;

    let updatedProductData = {
      quantityOnHand:
        originalOrder.type === 'purchase'
          ? product.quantityOnHand - originalOrder.quantity
          : product.quantityOnHand + originalOrder.quantity
    };
    //update product data
    if (newOrderData.type === 'purchase') {
      updatedProductData.quantityOnHand += newQty;
    } else {
      updatedProductData.quantityOnHand -= newQty;
    }

    let newInventoryTransaction = {
      editedAt: time,
      amount: newPrice * newQty,
      type: newOrderData.type === 'purchase' ? 'Debit' : 'Credit',
      date: newOrderData.date,
      details: `${newOrderData.type} ${newQty} ${product.unit} of ${product.name}`,
      transactionId: originalOrder.inventoryTransactionId
    };

    let originalPaymentTransaction = null;
    let newPaymentTransaction = null;

    if (originalOrder.paymentAccountId !== newOrderData.paymentAccountId) {
      //if new payment isn't cash, will need to create a new transaction
      if (newOrderData.paymentAccountId !== 'cash') {
        newPaymentTransaction = {
          accountId: newOrderData.paymentAccountId,
          amount: newQty * newPrice,
          createdAt: time,
          orderId: originalOrder.orderId,
          date: newOrderData.date,
          details: `${newOrderData.type} ${newQty} ${product.unit} of ${product.name}`,
          type: newOrderData.type === 'purchase' ? 'Credit' : 'Debit',
          userId: user.credentials.userId
        }; //add transactionId field in dataActions
      }

      //if original payment wasn't cash, need to roll back changes in originalAccount
      //and user data, and then delete the original transaction
      if (originalOrder.paymentAccountId !== 'cash') {
        // delete original transaction
        originalPaymentTransaction = {
          delete: true,
          transactionId: originalOrder.paymentTransactionId,
          accountId: originalOrder.paymentAccountId
        };
      }
    } else {
      // if original and new payment accounts are same, but not cash
      if (originalOrder.paymentAccountId !== 'cash') {
        //modify original transaction
        originalPaymentTransaction = {
          transactionId: originalOrder.paymentTransactionId,
          editedAt: time,
          amount: newQty * newPrice,
          date: newOrderData.date,
          details: `${newOrderData.type} ${newQty} ${product.unit} of ${product.name}`,
          type: newOrderData.type === 'purchase' ? 'Credit' : 'Debit'
        };
      }
    }

    return editOrder({
      newOrderData,
      updatedProductData,
      originalPaymentTransaction,
      newPaymentTransaction,
      newInventoryTransaction
    });
  };

  const handleOrdersDelete = () => {
    let ordersToDelete = ordersList.filter((ord) =>
      selected.includes(ord.orderId)
    );

    let accountsToUpdate = {};

    let inventoryToUpdate = {
      productId: ordersToDelete[0]?.productId,
      quantityOnHand: user.products[ordersToDelete[0].productId].quantityOnHand,
      ordersToDelete: [...selected]
    };

    ordersToDelete.forEach((ord) => {
      if (ord.type === 'purchase') {
        inventoryToUpdate.quantityOnHand -= ord.quantity;
      } else {
        inventoryToUpdate.quantityOnHand += ord.quantity;
      }

      // add inventory transaction to be deleted
      let invAcc = {
        transactionsToDelete: accountsToUpdate[ord.inventoryAccountId]
          ? [
              ...accountsToUpdate[ord.inventoryAccountId].transactionsToDelete,
              ord.inventoryTransactionId
            ]
          : [ord.inventoryTransactionId]
      };

      accountsToUpdate[ord.inventoryAccountId] = { ...invAcc };

      //if order payment wasn't handled in cash, do the same with payment account
      if (ord.paymentAccountId !== 'cash') {
        // get updated account credits or debits (original if updated not found)
        let payAcc = {
          transactionsToDelete: accountsToUpdate[ord.paymentAccountId]
            ? [
                ...accountsToUpdate[ord.paymentAccountId].transactionsToDelete,
                ord.paymentTransactionId
              ]
            : [ord.paymentTransactionId]
        };
        accountsToUpdate[ord.paymentAccountId] = { ...payAcc };
      }
    });

    return new Promise((resolve, reject) => {
      deleteOrders(inventoryToUpdate, accountsToUpdate)
        .then(() => {
          setSelected([]);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <OrdersListToolbar
            numSelected={selected.length}
            handleAddOrderSubmit={handleAddOrderSubmit}
            handleOrdersDelete={handleOrdersDelete}
            inventoryAccountId={product.inventoryAccountId}
          />
          <TableContainer>
            <OrdersTable
              productOrders={ordersList}
              selected={selected}
              handleSelectAllClick={handleSelectAllClick}
              handleSelectClick={handleSelectClick}
              handleRowClick={handleRowClick}
              page={page}
              rowsPerPage={rowsPerPage}
              unit={product.unit}
            />
          </TableContainer>
          <HkTablePaginator
            count={ordersList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <OrderDialog
        open={openOrderDialog}
        handleClose={handleOrderDialogClose}
        origOrder={clickedOrder}
        handleAddOrderSubmit={handleAddOrderSubmit}
        inventoryAccountId={product.inventoryAccountId}
        handleEditOrderSubmit={handleEditOrderSubmit}
      />
    </>
  );
}

OrdersList.propTypes = {
  user: PropTypes.object.isRequired,
  addOrder: PropTypes.func.isRequired,
  deleteOrders: PropTypes.func.isRequired,
  editOrder: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, {
  addOrder,
  editOrder,
  deleteOrders
})(OrdersList);
