import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import AccountsTableHeader from "./AccountsTableHeader";
import AccountRow from "./AccountRow";
import { getComparator, stableSort } from "../../helpers/tableHelpers";

export default function AccountsTable({ accounts, page, rowsPerPage }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getSortedAccounts = () => {
    return stableSort(accounts, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - accounts.length) : 0;

  return (
    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
      <AccountsTableHeader
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
      <TableBody>
        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
        {getSortedAccounts().map((account, index) => {
          const labelId = `accounts-table-${index}`;

          return (
            <AccountRow
              account={account}
              key={account.accountId}
              labelId={labelId}
            />
          );
        })}
        {emptyRows > 0 && (
          <TableRow
            style={{
              height: 53 * emptyRows
            }}
          >
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
