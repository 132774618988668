import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useRoutes } from "react-router-dom";
import getRoutes from "./routes";
//MUI
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./styles/Theme";
import MuiBackdrop from "./components/Backdrop/Backdrop";
import { StyleSheetManager } from "styled-components";
import rtlPlugin from "stylis-plugin-rtl";

//redux
import { connect } from "react-redux";
import { getUserData, clearUserData } from "./redux/actions/dataActions";

//i18n
import { useTranslation } from "react-i18next";

//styles
import "./App.css";
import { useNavigate } from "react-router";

//firebase
import { auth } from "./firebase/firebase";

/************************IMPORTS END************************************/

function App({ getUserData, ui }) {
  const [ready, setReady] = useState(false);

  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    const unsubsribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getUserData(user.uid)
          .then(() => navigate("/app/dashboard"))
          .catch(() => {
            console.log("*****************")
            console.error(ui.errors)
          });
      } else {
        clearUserData();
        navigate("/login");
      }
      setReady(true);
    });

    return unsubsribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.body.dir = i18n.dir();

  const routes = getRoutes(!!auth.currentUser);
  const content = useRoutes(routes);

  return (
    <StyledEngineProvider injectFirst>
      <StyleSheetManager
        stylisPlugins={theme.direction === "rtl" ? [rtlPlugin] : []}
      >
        <ThemeProvider theme={theme}>
          {ui.loading || !ready ? (
            <MuiBackdrop open={ui.loading || !ready} />
          ) : (
            content
          )}
        </ThemeProvider>
      </StyleSheetManager>
    </StyledEngineProvider>
  );
}

App.propTypes = {
  ui: PropTypes.object.isRequired,
  getUserData: PropTypes.func.isRequired,
  clearUserData: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  ui: state.ui
});

const mapActionsToState = {
  getUserData,
  clearUserData
};
export default connect(mapStateToProps, mapActionsToState)(App);
