import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import OrdersList from "./OrdersList";
import ProductDetailsHeader from "./ProductDetailsHeader";
import { connect } from "react-redux";
import { deleteProduct } from "../../redux/actions/dataActions";

function ProductContainer({ products, productId, deleteProduct }) {
  const [product, setProduct] = useState({ orders: [] });

  useEffect(() => {
    if (productId && products.hasOwnProperty(productId)) {
      setProduct(products[productId]);
    }
    return () => {
      setProduct({ orders: [] });
    };
  }, [productId, products]);

  const handleProductDelete = () => {
    return deleteProduct(productId, product.inventoryAccountId);
  };

  return (
    !!product && (
      <>
        <ProductDetailsHeader
          product={product}
          //   accountBalance={accountBalance}
          //   accountCredits={accountCredits}
          //   accountDebits={accountDebits}
          handleProductDelete={handleProductDelete}
        />
        <Box sx={{ pt: 3 }}>
          <OrdersList product={product} />
        </Box>
      </>
    )
  );
}

ProductContainer.propTypes = {
  products: PropTypes.object.isRequired,
  productId: PropTypes.string.isRequired,
  deleteProduct: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  products: state.user.products
});

export default connect(mapStateToProps, { deleteProduct })(ProductContainer);
