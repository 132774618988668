import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Box } from '@mui/system';
import { Paper, TableContainer } from '@mui/material';
import AccountsListToolbar from './AccountsListToolbar';
import AccountsTable from './AccountsTable';
import HkTablePaginator from '../CustomizedMui/HkTablePaginator';
import { setAccount } from '../../redux/actions/dataActions';
import HkTextField from '../CustomizedMui/HkTextField';
import { useTranslation } from 'react-i18next';
/***************************************************************************/

function AccountsList({ accounts, credentials, setAccount }) {
  const [accountsList, setAccountsList] = useState([]);
  const [filteredAccountsList, setFilteredAccountsList] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const { t } = useTranslation();

  useEffect(() => {
    let list = Object.values(accounts).map((acc) => ({
      ...acc,
      balance: acc.transactions.reduce(
        (bal, t) => (t.type === 'Credit' ? bal + t.amount : bal - t.amount),
        0
      )
    }));
    setAccountsList(list);
    setFilteredAccountsList(list);
    setRowsPerPage(list.length);
    return () => {
      setAccountsList([]);
    };
  }, [accounts]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddAccountSubmit = (accountData) => {
    accountData.userId = credentials.userId;
    accountData.createdAt = new Date().toISOString();

    return setAccount(accountData);
  };

  const handleSearchAccountChange = (e) => {
    if (e.target.value.length === 0) {
      setRowsPerPage(accountsList.length);
      setFilteredAccountsList(accountsList);
      return;
    }
    let filteredAccounts = accountsList.filter((acc) =>
      acc.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredAccountsList(filteredAccounts);
    setRowsPerPage(filteredAccounts.length);
  };

  return (
    <Box data-testid="accounts-list" sx={{ width: '100%' }}>
      <HkTextField
        placeholder={t('ssearch_accounts')}
        sx={{ mb: 2 }}
        onChange={handleSearchAccountChange}
      />
      <Paper sx={{ width: '100%', mb: 2 }}>
        <AccountsListToolbar handleAddAccountSubmit={handleAddAccountSubmit} />
        <TableContainer>
          <AccountsTable
            accounts={filteredAccountsList}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </TableContainer>
        <HkTablePaginator
          count={filteredAccountsList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

AccountsList.propTypes = {
  accounts: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
  setAccount: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  accounts: state.user.accounts,
  credentials: state.user.credentials
});

export default connect(mapStateToProps, { setAccount })(AccountsList);
