import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { Box, useTheme } from "@mui/system";
import { CircularProgress, Typography } from "@mui/material";
import HkButton from "../CustomizedMui/HkButton";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  error: theme.customClasses.error,
  progressCircle: theme.customClasses.progressCircle
}));
export default function AlertDialog(props) {
  const {
    open,
    closeButtonText,
    closeButtonColor,
    submitButtonText,
    handleDialogClose,
    handleDialogSubmit,
    title,
    goToOnSuccess
  } = props;
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    setLoading(true);
    handleDialogSubmit()
      .then(() => {
        if (goToOnSuccess) {
          navigate(goToOnSuccess);
        } else {
          handleClose();
        }
      })
      .catch((err) => {
        setErrors({ general: err });
        setLoading(false);
      });
  };
  const handleClose = () => {
    setErrors(null);
    setLoading(false);
    handleDialogClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog"
        aria-describedby="alert-dialog"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontFamily: theme.customVars.urduFont }}
          >
            {props.children}
          </DialogContentText>
          {errors && (
            <Typography
              className={classes.error}
              variant="body2"
              sx={{ fontFamily: theme.customVars.urduFont }}
            >
              <Box
                component="span"
                sx={
                  theme.direction === "rtl"
                    ? { fontFamily: theme.customVars.urduFont }
                    : {}
                }
              >
                {errors.general}
              </Box>
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <HkButton
            color={closeButtonColor ?? "secondary"}
            onClick={handleClose}
          >
            {closeButtonText ?? t("cancel")}
          </HkButton>
          {handleDialogSubmit && (
            <HkButton
              data-testid="alert-submit-btn"
              onClick={handleSubmit}
              disabled={loading}
              autoFocus
            >
              {loading && (
                <CircularProgress
                  className={classes.progressCircle}
                  size={25}
                  color="secondary"
                />
              )}
              {submitButtonText ?? t("submit")}
            </HkButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
