import { Container } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import AccountContainer from "../components/AccountDetails/AccountContainer";

function AccountDetailsPage() {
  let { accountId } = useParams();

  return (
    <>
      <Helmet>
        <title>Account Details | HisaabKitaab</title>
      </Helmet>
      <Box sx={{ minHeight: "100%", py: 3 }}>
        <Container maxWidth={false}>
          <AccountContainer accountId={accountId} />
        </Container>
      </Box>
    </>
  );
}

export default AccountDetailsPage;
