//USER Reducer Types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const LOADING_USER = "LOADING_USER";
export const SET_USER = "SET_USER";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const UPDATE_ACCOUNT_DETAILS = "UPDATE_ACCOUNT_DETAILS";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const EDIT_USER = "EDIT_USER";
export const CLEAR_USER = "CLEAR_USER";
export const SET_CREDENTIALS = "SET_CREDENTIALS";

//UI Reducer Types
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const STOP_LOADING_UI = "STOP_LOADING_UI";

//Data Reducer Types
export const LOADING_DATA = "LOADING_DATA";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const EDIT_TRANSACTION = "EDIT_TRANSACTION";
export const DELETE_TRANSACTIONS = "DELETE_TRANSACTIONS";
export const SETTLE_ACCOUNT = "SETTLE_ACCOUNT";
export const UNSET_ACCOUNT = "UNSET_ACCOUNT";

//PRODUCT REDUCERS
export const ADD_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

//ORDER REDUCER TYPES
export const ADD_ORDER = "ADD_ORDER";
export const EDIT_ORDER = "EDIT_ORDER";
export const DELETE_ORDERS = "DELETE_ORDERS";
