import {
  SET_USER,
  ADD_ACCOUNT,
  EDIT_ACCOUNT,
  DELETE_ACCOUNT,
  CLEAR_USER,
  ADD_TRANSACTION,
  EDIT_TRANSACTION,
  DELETE_TRANSACTIONS,
  ADD_PRODUCT,
  EDIT_PRODUCT,
  ADD_ORDER,
  EDIT_ORDER,
  DELETE_ORDERS,
  DELETE_PRODUCT,
  SET_CREDENTIALS
} from "../types";
import produce from "immer";

const initialState = {
  authenticated: false,
  loading: false,
  userCredits: 0,
  userDebits: 0,
  numCreditAccounts: 0,
  numDebitAccounts: 0,
  credentials: {},
  accounts: {},
  products: {},
  historicalCreditDebit: {}
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return produce(state, (draftState) => ({
        ...draftState,
        authenticated: true,
        ...action.payload
      }));

    case SET_CREDENTIALS:
      return produce(state, (draftState) => ({
        ...draftState,
        credentials: {
          ...draftState.credentials,
          ...action.payload
        }
      }));

    case ADD_ACCOUNT:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [action.payload.accountId]: {
            ...action.payload,
            accountCredits: 0,
            accountDebits: 0,
            transactions: []
          }
        }
      };

    case EDIT_ACCOUNT:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [action.payload.accountId]: {
            ...state.accounts[action.payload.accountId],
            ...action.payload
          }
        }
      };

    case DELETE_ACCOUNT:
      var { [action.payload]: deletedAccount, ...accountsToKeep } =
        state.accounts;

      return {
        ...state,
        accounts: accountsToKeep
      };

    case ADD_TRANSACTION:
      return produce(state, (draftState) => {
        draftState.accounts[action.payload.accountId].transactions.push(
          action.payload
        );
      });

    case EDIT_TRANSACTION:
      return produce(state, (draftState) => {
        let newTransactions = draftState.accounts[
          action.payload.accountId
        ].transactions.map((t) =>
          t.transactionId === action.payload.transactionId ? action.payload : t
        );
        draftState.accounts[action.payload.accountId].transactions =
          newTransactions;
      });

    case DELETE_TRANSACTIONS:
      let { transactionsToDelete, accountId } = action.payload;

      return produce(state, (draftState) => {
        let transactionToKeep = draftState.accounts[
          accountId
        ].transactions.filter(
          (t) => !transactionsToDelete.includes(t.transactionId)
        );
        draftState.accounts[action.payload.accountId].transactions =
          transactionToKeep;
      });

    case ADD_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.productId]: {
            ...action.payload,
            orders: []
          }
        }
      };

    case EDIT_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.productId]: {
            ...state.products[action.payload.productId],
            ...action.payload
          }
        }
      };

    case DELETE_PRODUCT:
      // eslint-disable-next-line no-redeclare
      var { [action.payload?.accountId]: deletedAccount, ...accountsToKeep } =
        state.accounts;
      var { [action.payload.productId]: deletedProduct, ...productsToKeep } =
        state.products;
      return {
        ...state,
        accounts: accountsToKeep,
        products: productsToKeep
      };

    case ADD_ORDER:
      return produce(state, (draftState) => {
        let {
          orderData,
          updatedProductData,
          inventoryTransactionData,
          paymentTransactionData
        } = action.payload;

        //add inventory transaction
        draftState.accounts[
          inventoryTransactionData.accountId
        ].transactions.unshift(inventoryTransactionData);
        //if there is paymentTransactionData, add it to paymentAccount
        if (paymentTransactionData) {
          draftState.accounts[
            paymentTransactionData.accountId
          ].transactions.unshift(paymentTransactionData);
        }
        //updated product with new data and new order
        draftState.products[orderData.productId] = {
          ...draftState.products[orderData.productId],
          ...updatedProductData
        };
        draftState.products[orderData.productId].orders.unshift(orderData);
      });

    case EDIT_ORDER:
      return produce(state, (draftState) => {
        let {
          orderData: newOrderData,
          updatedProductData,
          originalPaymentTransaction,
          newPaymentTransaction,
          newInventoryTransaction
        } = action.payload;

        //update order
        let index = draftState.products[
          newOrderData.productId
        ].orders.findIndex((ord) => ord.orderId === newOrderData.orderId);
        if (index >= 0)
          draftState.products[newOrderData.productId].orders[index] = {
            ...draftState.products[newOrderData.productId].orders[index],
            ...newOrderData
          };

        //updated product
        draftState.products[newOrderData.productId] = {
          ...draftState.products[newOrderData.productId],
          ...updatedProductData
        };

        //update originalPaymentTransaction
        if (originalPaymentTransaction) {
          //if original payment deleted
          if (originalPaymentTransaction.hasOwnProperty("delete")) {
            draftState.accounts[
              originalPaymentTransaction.accountId
            ].transactions = draftState.accounts[
              originalPaymentTransaction.accountId
            ].transactions.filter(
              (t) =>
                t.transactionId !== originalPaymentTransaction.transactionId
            );
          } else {
            //update original payment transaction
            let index = draftState.accounts[
              newOrderData.paymentAccountId
            ].transactions.findIndex(
              (t) =>
                originalPaymentTransaction.transactionId === t.transactionId
            );
            if (index >= 0)
              draftState.accounts[newOrderData.paymentAccountId].transactions[
                index
              ] = {
                ...draftState.accounts[newOrderData.paymentAccountId]
                  .transactions[index],
                ...originalPaymentTransaction
              };
          }
        }

        //update inventory transaction
        index = draftState.accounts[
          newOrderData.inventoryAccountId
        ].transactions.findIndex(
          (t) => t.transactionId === newInventoryTransaction.transactionId
        );
        if (index >= 0)
          draftState.accounts[newOrderData.inventoryAccountId].transactions[
            index
          ] = {
            ...draftState.accounts[newOrderData.inventoryAccountId]
              .transactions[index],
            ...newInventoryTransaction
          };

        //add newPaymentTransaction if not null
        if (newPaymentTransaction)
          draftState.accounts[
            newOrderData.paymentAccountId
          ].transactions.unshift(newPaymentTransaction);
      });

    case DELETE_ORDERS:
      return produce(state, (draftState) => {
        let { inventoryToUpdate, accountsToUpdate } = action.payload;
        Object.keys(accountsToUpdate).forEach((id) => {
          draftState.accounts[id].transactions = draftState.accounts[
            id
          ].transactions.filter(
            (t) =>
              !accountsToUpdate[id].transactionsToDelete.includes(
                t.transactionId
              )
          );
        });
        let productId = inventoryToUpdate.productId;
        draftState.products[productId].quantityOnHand =
          inventoryToUpdate.quantityOnHand;
        draftState.products[productId].orders = draftState.products[
          productId
        ].orders.filter(
          (ord) => !inventoryToUpdate.ordersToDelete.includes(ord.orderId)
        );
      });

    case CLEAR_USER:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

export default userReducer;
