import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/system";

function OrdersListHeader(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    unit
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  const headerCells = [
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: t("date")
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: t("type")
    },
    {
      id: "quantity",
      numeric: true,
      disablePadding: false,
      label: `${t("quantity")} (${t(unit)})`
    },
    {
      id: "price",
      numeric: true,
      disablePadding: false,
      label: t("price_rs")
    },
    {
      id: "amount",
      numeric: true,
      disablePadding: false,
      label: t("total_amount_rs")
    },
    {
      id: "paymentAccount",
      numeric: true,
      disablePadding: false,
      label: t("payment_account")
    },
    {
      id: "details",
      numeric: false,
      disablePadding: false,
      label: t("details")
    }
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": t("select_all_orders")
            }}
          />
        </TableCell>
        {headerCells.map((headerCell) => (
          <TableCell
            key={headerCell.id}
            align={theme.direction === "ltr" ? "left" : "right"}
            padding={headerCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headerCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headerCell.id}
              direction={orderBy === headerCell.id ? order : "asc"}
              onClick={createSortHandler(headerCell.id)}
              dir="ltr"
              sx={
                theme.direction === "rtl"
                  ? { fontFamily: theme.customVars.urduFont }
                  : {}
              }
            >
              {headerCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

OrdersListHeader.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default OrdersListHeader;
