import React, { useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import Balance from "./Balance";
import Credits from "./Credits";
import Debits from "./Debits";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountDialog from "../Dialogs/AccountDialog";
import { setAccount } from "../../redux/actions/dataActions";
import { useTheme } from "@mui/system";

/********************************************************* */

function AccountDetailsHeader({
  account,
  accountBalance,
  accountCredits,
  accountDebits,
  setAccount,
  handleAccountDelete,
  products
}) {
  const [openAddAccountDialog, setOpenAddAccountDialog] = useState(false);

  const theme = useTheme();

  const handleAddAccountDialogOpen = () => {
    setOpenAddAccountDialog(true);
  };
  const handleAddAccountDialogClose = () => {
    setOpenAddAccountDialog(false);
  };

  const handleEditAccountSubmit = (newData) => {
    newData.editedAt = new Date().toISOString();
    newData.accountId = account.accountId;
    return setAccount(newData);
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%"
      }}
    >
      <Grid container spacing={3} wrap="nowrap">
        <Grid item xs={11}>
          <div>
            <Typography
              variant={theme.direction === "rtl" ? "h6" : "h4"}
              sx={
                theme.direction === "rtl"
                  ? {
                      fontFamily: theme.customVars.urduFont,
                      lineHeight: "2em"
                    }
                  : {}
              }
              noWrap
            >
              {account.name}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            sx={{ alignSelf: "center" }}
            onClick={handleAddAccountDialogOpen}
          >
            <SettingsIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={3} pt={3}>
        <Grid item lg={4} md={4} s={4} xs={12}>
          <Balance balance={accountBalance} />
        </Grid>
        <Grid item lg={4} md={4} s={4} xs={12}>
          <Credits amount={accountCredits} />
        </Grid>
        <Grid item lg={4} md={4} s={4} xs={12}>
          <Debits amount={accountDebits} />
        </Grid>
      </Grid>
      <AccountDialog
        open={openAddAccountDialog}
        handleClose={handleAddAccountDialogClose}
        handleSubmit={handleEditAccountSubmit}
        origDetails={account}
        handleAccountDelete={handleAccountDelete}
        isInventoryAccountDeletable={
          account.hasOwnProperty("productId") &&
          !products.hasOwnProperty(account.productId)
        }
      />
    </Box>
  );
}

AccountDetailsHeader.propTypes = {
  setAccount: PropTypes.func.isRequired,
  handleAccountDelete: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  accountBalance: PropTypes.number.isRequired,
  accountCredits: PropTypes.number.isRequired,
  accountDebits: PropTypes.number.isRequired,
  products: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  products: state.user.products
});

export default connect(mapStateToProps, { setAccount })(AccountDetailsHeader);
