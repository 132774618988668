import React from "react";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import { useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";

export default function OrderRow(props) {
  const {
    productOrder,
    handleSelectClick,
    isItemSelected,
    handleRowClick,
    labelId
  } = props;

  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <TableRow
      hover
      onClick={(event) => handleRowClick(event, productOrder.orderId)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox
          onClick={(event) => handleSelectClick(event, productOrder.orderId)}
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId
          }}
        />
      </TableCell>

      <TableCell
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        align={theme.direction === "ltr" ? "left" : "right"}
      >
        {productOrder.date}
      </TableCell>
      <TableCell
        align={theme.direction === "ltr" ? "left" : "right"}
        sx={{ fontFamily: theme.customVars.urduFont }}
      >
        {t(productOrder.type)}
      </TableCell>
      <TableCell dir="ltr" align={theme.direction === "ltr" ? "left" : "right"}>
        {productOrder.quantity}
      </TableCell>
      <TableCell dir="ltr" align={theme.direction === "ltr" ? "left" : "right"}>
        {productOrder.price.toLocaleString()}
      </TableCell>

      <TableCell dir="ltr" align={theme.direction === "ltr" ? "left" : "right"}>
        {productOrder.amount.toLocaleString()}
      </TableCell>
      <TableCell
        align={theme.direction === "ltr" ? "left" : "right"}
        sx={{ fontFamily: theme.customVars.urduFont }}
      >
        {t(productOrder.paymentAccount)}
      </TableCell>
      <TableCell
        align={theme.direction === "ltr" ? "left" : "right"}
        sx={{ fontFamily: theme.customVars.urduFont }}
      >
        {productOrder.details}
      </TableCell>
    </TableRow>
  );
}
