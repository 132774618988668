import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  TextField,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box, useTheme } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import HkBotton from "../components/CustomizedMui/HkButton";
import AlertDialog from "../components/Dialogs/AlertDialog";
import authentication from "../firebase/authentication";

const useStyles = makeStyles((theme) => ({
  error: theme.customClasses.error,
  progressCircle: theme.customClasses.progressCircle
}));
function ForgotPassword(props) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const validationSchema = yup.object({
    email: yup
      .string(t("email"))
      .email("Enter a valid email")
      .required("Email address is required.")
  });
  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      authentication
        .resetPassword(values.email)
        .then(() => {
          setLoading(false);
          setOpenAlertDialog(true);
        })
        .catch((err) => {
          console.error(err);
          setErrors({ general: err.message });
          setLoading(false);
        });
    }
  });

  useEffect(() => {
    return () => formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xs" sx={{ p: 10, height: "100vh" }}>
      <form onSubmit={formik.handleSubmit} id="forgot-password-form">
        <Card elevation={2}>
          <CardHeader
            title={"Enter your email:"}
            titleTypographyProps={{ variant: "h6", color: "textSecondary" }}
          />
          <Divider />
          <CardContent>
            <TextField
              data-testid="email"
              id="email"
              name="email"
              type="email"
              placeholder={"Email"}
              label="Email"
              variant="outlined"
              margin="normal"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              value={formik.values.email}
              onChange={formik.handleChange}
              fullWidth
            />

            {errors && (
              <Typography className={classes.error} variant="body2">
                <Box
                  component="span"
                  sx={
                    theme.direction === "rtl"
                      ? { fontFamily: theme.customVars.urduFont }
                      : {}
                  }
                >
                  {errors.general}
                </Box>
              </Typography>
            )}
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 2
            }}
          >
            <HkBotton
              data-testid="email-submit-button"
              color="primary"
              form="forgot-password-form"
              type="submit"
              variant="contained"
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  className={classes.progressCircle}
                  size={25}
                  color="secondary"
                />
              )}
              Submit
            </HkBotton>
          </Box>
        </Card>
      </form>
      <AlertDialog
        open={openAlertDialog}
        handleDialogClose={() => setOpenAlertDialog(false)}
        title={"Password Reset"}
        closeButtonText={"Close"}
        closeButtonColor={"success"}
      >
        Reset email sent. Please follow instructions in the email to reset your
        password.
      </AlertDialog>
    </Container>
  );
}

export default ForgotPassword;
