import { Container } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet";
import AccountsList from "../components/AccountsList/AccountsList";

function AccountsPage(props) {
  return (
    <>
      <Helmet>
        <title>Accounts | HisaabKitaab</title>
      </Helmet>
      <Box sx={{ minHeight: "100%", py: 3 }}>
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <AccountsList />
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default AccountsPage;
