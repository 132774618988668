import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, useTheme } from "@mui/system";
import HkTextField from "../CustomizedMui/HkTextField";
import {
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import HkButton from "../CustomizedMui/HkButton";
import { Marginer } from "../Marginer/Marginer";
import ResponsiveDialog from "./ResponsiveDialog";
import MuiBackdrop from "../Backdrop/Backdrop";
import AccountsAutocomplete from "./AccountsAutocomplete";

const useStyles = makeStyles((theme) => ({
  error: theme.customClasses.error,
  progressCircle: theme.customClasses.progressCircle,
  selectField: {
    marginTop: "10px",
    fontSize: "13px",
    minHeight: "25px",
    lineHeight: "200%"
  },
  selectIcon: {
    top: "calc(50% - 6px)",
    left: "0 !important"
  }
}));
function OrderDialog(props) {
  const {
    open,
    handleClose,
    origOrder,
    inventoryAccountId,
    handleAddOrderSubmit,
    handleEditOrderSubmit
  } = props;
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const typeRegExp = /^purchase$|^sale$/;

  const validationSchema = yup.object({
    date: yup.date().required("Select a date"),
    type: yup
      .string(t("type"))
      .matches(typeRegExp, 'Invalid type. Must be "Sale" or "Purchase"')
      .required("Select a type."),
    quantity: yup
      .number()
      .typeError("Must be a number")
      .positive("Quantity must be greater than zero")
      .required("Price is required"),
    price: yup
      .number()
      .typeError("Must be a number")
      .min(0, "Price must be atleast zero")
      .required("Price is required"),
    details: yup.string(t("details")),
    paymentAccountId: yup
      .string(t("paymentAccount"))
      .required("Select payment account.")
  });
  const formik = useFormik({
    initialValues: {
      date: origOrder?.date ?? new Date().toISOString().split("T")[0],
      type: origOrder?.type ?? "purchase",
      quantity: origOrder?.quantity ?? "",
      price: origOrder?.price ?? "",
      details: origOrder?.details ?? "",
      paymentAccountId: origOrder?.paymentAccountId ?? "cash"
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (origOrder) {
          await handleEditOrderSubmit(values);
          handleDialogClose();
        } else {
          await handleAddOrderSubmit(values);
          handleDialogClose();
        }
      } catch (err) {
        console.log(err);
        setErrors({ general: err });
      }

      setLoading(false);
    }
  });

  useEffect(() => {
    if (origOrder) {
      formik.values.date = origOrder.date;
      formik.values.price = origOrder.price;
      formik.values.type = origOrder.type;
      formik.values.details = origOrder.details;
      formik.values.quantity = origOrder.quantity;
      formik.values.paymentAccountId = origOrder.paymentAccountId;
    }

    return () => {
      setErrors(null);
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origOrder, inventoryAccountId]);

  const handleDialogClose = () => {
    formik.resetForm();
    setErrors(null);
    setLoading(false);
    handleClose();
  };

  const dialogTitle = (
    <Box
      component="span"
      sx={
        theme.direction === "rtl"
          ? { fontFamily: theme.customVars.urduFont }
          : {}
      }
    >
      {t("order")}
    </Box>
  );
  const dialogContent = (
    <form
      data-testid="order-form"
      onSubmit={formik.handleSubmit}
      id="order-form"
    >
      <HkTextField
        dir="ltr"
        id="date"
        name="date"
        type="date"
        placeholder={t("date")}
        margin="normal"
        error={formik.touched.date && Boolean(formik.errors.date)}
        helperText={formik.touched.date && formik.errors.date}
        value={formik.values.date}
        onChange={formik.handleChange}
        sx={{ input: { fontFamily: theme.typography.body1.fontFamily } }}
        fullWidth={theme.direction !== "rtl"}
        autoFocus
      />
      <RadioGroup
        row
        label="type"
        id="type"
        name="type"
        value={formik.values.type}
        onChange={formik.handleChange}
        sx={{
          pt: 2,
          legend: { fontFamily: theme.customVars.urduFont },
          span: { fontFamily: theme.customVars.urduFont }
        }}
      >
        <FormLabel component="legend" sx={{ pt: 1.1 }}>
          {t("order_type")}:
        </FormLabel>
        <FormControlLabel
          value="purchase"
          control={<Radio />}
          label={t("purchase")}
          sx={{ fontFamily: theme.customVars.urduFont }}
        />
        <FormControlLabel
          value="sale"
          control={<Radio />}
          label={t("sale")}
          componentsProps={{
            style: { fontFamily: theme.customVars.urduFont }
          }}
        />
      </RadioGroup>

      <HkTextField
        id="quantity"
        name="quantity"
        type="text"
        placeholder={t("quantity")}
        margin="normal"
        error={formik.touched.quantity && Boolean(formik.errors.quantity)}
        helperText={formik.touched.quantity && formik.errors.quantity}
        value={formik.values.quantity}
        onChange={formik.handleChange}
        fullWidth
      />
      <HkTextField
        id="price"
        name="price"
        type="text"
        placeholder={t("price")}
        margin="normal"
        error={formik.touched.price && Boolean(formik.errors.price)}
        helperText={formik.touched.price && formik.errors.price}
        value={formik.values.price}
        onChange={formik.handleChange}
        fullWidth
      />
      <HkTextField
        id="details"
        name="details"
        placeholder={t("details")}
        margin="normal"
        error={formik.touched.details && Boolean(formik.errors.details)}
        helperText={formik.touched.details && formik.errors.details}
        value={formik.values.details}
        onChange={formik.handleChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <AccountsAutocomplete
        handleAccountChange={(acc) => {
          formik.setFieldValue("paymentAccountId", acc);
        }}
        defaultOption={{ name: t("cash"), id: "cash" }}
        selectedValue={
          formik.values.paymentAccountId !== "cash"
            ? formik.values.paymentAccountId
            : undefined
        }
        placeholderText={t("select_payment_account")}
      />

      {errors && (
        <Typography className={classes.error} variant="body2">
          <Box
            component="span"
            sx={
              theme.direction === "rtl"
                ? { fontFamily: theme.customVars.urduFont }
                : {}
            }
          >
            {errors["general"]}
          </Box>
        </Typography>
      )}
    </form>
  );

  const dialogActions = (
    <>
      <HkButton onClick={handleDialogClose} color="secondary">
        {t("cancel")}
      </HkButton>
      <Marginer direction="horizontal" margin="10px" />
      <HkButton
        data-testid="trans-submit"
        color="primary"
        form="order-form"
        type="submit"
        variant="contained"
        disabled={loading}
      >
        {loading && (
          <CircularProgress
            className={classes.progressCircle}
            size={25}
            color="secondary"
          />
        )}
        {t("submit")}
      </HkButton>
    </>
  );
  return (
    <>
      <ResponsiveDialog
        open={open}
        handleClose={handleDialogClose}
        dialogTitle={dialogTitle}
        dialogContent={dialogContent}
        dialogActions={dialogActions}
      />
      <MuiBackdrop open={loading} />
    </>
  );
}

OrderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  origTransaction: PropTypes.object,
  handleAddOrderSubmit: PropTypes.func.isRequired
};

export default OrderDialog;
