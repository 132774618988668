import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UserBalance from "./UserBalance";
import InventoryValue from "./InventoryValue";
import CreditDebitChart from "./CreditDebitChart";
import BankAccountsSummary from "./BankAccountsSummary";

function DashboardContainer({ user }) {
  const { t } = useTranslation();
  const [balance, setBalance] = useState(0);
  const [accountsHistory, setAccountsHistory] = useState({});
  const [bankBalance, setBankBalance] = useState(0);
  const [topBankAccounts, setTopBankAccounts] = useState([]);
  const [inventoryValue, setInventoryValue] = useState(0);
  //calculate total balance, bank balance,
  //top bank accounts, and Income/expense history
  useMemo(() => {
    let runningBal = 0;
    let accHistory = {};
    let bankBal = 0;
    let bankAccts = {};
    //get start date for history
    let yearStartDate = new Date();
    yearStartDate.setFullYear(yearStartDate.getFullYear() - 1);
    yearStartDate.setDate(1);
    yearStartDate = yearStartDate.toISOString().split("T")[0];

    Object.values(user.accounts).forEach((acc) => {
      if (acc.category === "BankAccount" && !bankAccts[acc.accountId]) {
        bankAccts[acc.accountId] = {
          balance: 0,
          name: acc.name,
          accountId: acc.accountId
        };
      }
      acc.transactions.forEach((t) => {
        //add to history if after transaction data
        if (t.date >= yearStartDate) {
          let key = t.date.slice(0, 7);
          if (!accHistory[key]) {
            accHistory[key] = [0, 0];
          }
          if (
            acc.category === "Inventory" ||
            acc.category === "Expense" ||
            acc.category === "Income"
          ) {
            if (t.type === "Credit") {
              accHistory[key][0] += t.amount;
            } else {
              accHistory[key][1] += t.amount;
            }
          }
        }
        //if bank account
        if (acc.category === "BankAccount") {
          if (t.type === "Debit") {
            bankBal += t.amount;
            bankAccts[acc.accountId].balance += t.amount;
          } else {
            bankBal -= t.amount;
            bankAccts[acc.accountId].balance -= t.amount;
          }
        }
        if (t.type === "Credit") {
          runningBal += t.amount;
        } else {
          runningBal -= t.amount;
        }
      });
    });
    setBalance(runningBal);
    setAccountsHistory(accHistory);
    setBankBalance(bankBal);
    setTopBankAccounts(
      Object.values(bankAccts)
        .sort((a, b) => b.balance - a.balance)
        .slice(0, 3)
    );
  }, [user.accounts]);

  //calculate total value of inventory
  useMemo(() => {
    let totalVal = 0;
    Object.values(user.products).forEach((product) => {
      totalVal += product.quantityOnHand * product.price;
    });
    setInventoryValue(totalVal);
  }, [user.products]);

  const getBalanceStr = () => {
    let balanceStr = `${Math.abs(balance).toLocaleString()}`;

    if (balance > 0) {
      balanceStr = `${balanceStr} (${t("Cr")})`;
    } else if (balance < 0) {
      balanceStr = `${balanceStr} (${t("Db")})`;
    }

    return balanceStr;
  };
  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <UserBalance balance={getBalanceStr()} />
        </Grid>
        <Grid item md={6} xs={12}>
          <InventoryValue value={inventoryValue} />
        </Grid>
        <Grid item md={8} xl={9} xs={12}>
          <CreditDebitChart dataHistory={accountsHistory} />
        </Grid>
        <Grid item md={4} xl={3} xs={12}>
          <BankAccountsSummary
            topBankAccounts={topBankAccounts}
            bankBalance={bankBalance}
            sx={{ height: "100%" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

DashboardContainer.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(DashboardContainer);
