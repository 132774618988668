import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box, useTheme } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as yup from "yup";
import HkBotton from "../CustomizedMui/HkButton";
import HkTextField from "../CustomizedMui/HkTextField";
import { changeCredentials } from "../../redux/actions/userActions";

const useStyles = makeStyles((theme) => ({
  error: theme.customClasses.error,
  progressCircle: theme.customClasses.progressCircle
}));
function Profile({ credentials, changeCredentials }) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const validationSchema = yup.object({
    name: yup.string(t("name")).required("Name is required"),
    organization: yup.string(t("name")).required("Name is required")
  });
  const formik = useFormik({
    initialValues: {
      name: credentials?.name ?? "",
      organization: credentials?.organization ?? ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      changeCredentials({ ...values, userId: credentials.userId })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setErrors({ general: err.message });
          setLoading(false);
        });
    }
  });

  useEffect(() => {
    return () => formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} id="profile-form">
      <Card>
        <CardHeader
          title={t("profile_details")}
          titleTypographyProps={{
            sx: { fontFamily: theme.customVars.urduFont },
            variant: theme.direction === "rtl" ? "h6" : "h5"
          }}
        />
        <Divider />
        <CardContent>
          <HkTextField
            data-testid="name"
            id="name"
            name="name"
            type="text"
            placeholder={t("name")}
            margin="normal"
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            value={formik.values.name}
            onChange={formik.handleChange}
            style={{ textTransform: "capitalize" }}
            fullWidth
          />
          <HkTextField
            data-testid="email"
            id="email"
            name="email"
            type="email"
            placeholder={t("email")}
            disabled
            margin="normal"
            value={credentials.email}
            fullWidth
          />

          <HkTextField
            data-testid="organization"
            id="organization"
            name="organization"
            type="text"
            placeholder={t("organization")}
            margin="normal"
            error={
              formik.touched.organization && Boolean(formik.errors.organization)
            }
            helperText={
              formik.touched.organization && formik.errors.organization
            }
            value={formik.values.organization}
            onChange={formik.handleChange}
            style={{ textTransform: "capitalize" }}
            fullWidth
          />
          {errors && (
            <Typography className={classes.error} variant="body2">
              <Box
                component="span"
                sx={
                  theme.direction === "rtl"
                    ? { fontFamily: theme.customVars.urduFont }
                    : {}
                }
              >
                {errors.general}
              </Box>
            </Typography>
          )}
        </CardContent>
        <Divider />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2
          }}
        >
          <HkBotton
            data-testid="profile-submit-button"
            color="primary"
            form="profile-form"
            type="submit"
            variant="contained"
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                className={classes.progressCircle}
                size={25}
                color="secondary"
              />
            )}
            {t("save_details")}
          </HkBotton>
        </Box>
      </Card>
    </form>
  );
}
const mapStateToProps = (state) => ({
  credentials: state.user.credentials
});

export default connect(mapStateToProps, { changeCredentials })(Profile);
