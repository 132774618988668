import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Box, useTheme } from '@mui/system';
import { Paper, TableContainer, TablePagination } from '@mui/material';
import ProductsListToolbar from './ProductsListToolbar';
import ProductsTable from './ProductsTable';
import { useTranslation } from 'react-i18next';
import { addProduct } from '../../redux/actions/dataActions';

function ProductsList({ user = { accounts: [] }, addProduct }) {
  const [products, setProducts] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    setProducts(Object.values(user.products));

    return () => {
      setProducts([]);
    };
  }, [user.products]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddProductSubmit = (productData) => {
    productData.userId = user.credentials.userId;
    productData.createdAt = new Date().toISOString();

    //check if editing product details, if so, updated editedAt timestamp
    let isEditing = productData.hasOwnProperty('productId');

    if (isEditing) productData.editedAt = new Date().toISOString();

    return addProduct(productData);
  };

  return (
    <Box data-testid="accounts-list" sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <ProductsListToolbar handleAddProductSubmit={handleAddProductSubmit} />
        <TableContainer>
          <ProductsTable
            products={products}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={products.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('rows_per_page')}
          sx={
            theme.direction === 'rtl'
              ? {
                  '& .MuiTablePagination-selectLabel': {
                    fontFamily: theme.customVars.urduFont
                  },
                  '& .MuiInputBase-root.MuiTablePagination-input': {
                    marginRight: '8px',
                    marginLeft: '32px',
                    fontFamily: theme.customVars.urduFont
                  },
                  '& svg': {
                    marginTop: '3px'
                  },
                  '& .MuiTablePagination-displayedRows': {
                    direction: 'ltr',
                    fontFamily: theme.customVars.urduFont
                  }
                }
              : {}
          }
        />
      </Paper>
    </Box>
  );
}

ProductsList.propTypes = {
  user: PropTypes.object.isRequired,
  addProduct: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, { addProduct })(ProductsList);
