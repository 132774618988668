import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, useTheme } from "@mui/system";
import HkTextField from "../CustomizedMui/HkTextField";
import { CircularProgress, MenuItem, Typography } from "@mui/material";
import HkBotton from "../CustomizedMui/HkButton";
import { Marginer } from "../Marginer/Marginer";
import ResponsiveDialog from "./ResponsiveDialog";
import MuiBackdrop from "../Backdrop/Backdrop";
import AlertDialog from "../Dialogs/AlertDialog";

const useStyles = makeStyles((theme) => ({
  error: theme.customClasses.error,
  progressCircle: theme.customClasses.progressCircle,
  selectField: {
    marginTop: "10px",
    fontSize: "13px",
    minHeight: "25px",
    lineHeight: "200%"
  },
  selectIcon: {
    top: "calc(50% - 6px)",
    left: "0 !important"
  }
}));
function AddAccountDialog(props) {
  const {
    open,
    handleClose,
    handleSubmit,
    origDetails,
    handleAccountDelete,
    isInventoryAccountDeletable
  } = props;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = yup.object({
    name: yup.string(t("name")).required("Name is required"),
    email: yup.string(t("email")).email("Enter a valid email"),
    phoneNum: yup.string().matches(phoneRegExp, "Enter a valid phone number."),
    category: yup.string().required("Select a category.")
  });
  const formik = useFormik({
    initialValues: {
      name: origDetails?.name ?? "",
      email: origDetails?.email ?? "",
      phoneNum: origDetails?.phoneNum ?? "",
      category: origDetails?.category ?? "Individual"
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      handleSubmit(values)
        .then(() => handleDialogClose())
        .catch((err) => {
          setErrors({ general: err });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  useEffect(() => {
    if (origDetails) {
      formik.values.name = origDetails.name;
      formik.values.email = origDetails.email;
      formik.values.phoneNum = origDetails.phoneNum;
      formik.values.category = origDetails.category;
    }

    return () => {
      setErrors(null);
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origDetails]);

  const handleDialogClose = () => {
    formik.resetForm();
    setErrors(null);
    setLoading(false);
    handleClose();
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteConfirmationDialog(false);
    handleClose();
  };
  const handleDeleteClick = () => {
    setOpenDeleteConfirmationDialog(true);
  };

  const dialogTitle = (
    <Box
      component="span"
      sx={
        theme.direction === "rtl"
          ? { fontFamily: theme.customVars.urduFont }
          : {}
      }
    >
      {origDetails ? t("edit_account") : t("add_new_account")}
    </Box>
  );

  const categories = [
    "Individual",
    "Business",
    "BankAccount",
    "BankLoan",
    "Expense",
    "Income",
    "Liability",
    "Asset",
    "Shareholder"
  ];
  const dialogContent = (
    <form
      data-testid="add-account-form"
      onSubmit={formik.handleSubmit}
      id="account-form"
    >
      <HkTextField
        data-testid="name"
        disabled={!!origDetails}
        id="name"
        name="name"
        type="text"
        placeholder={t("name")}
        margin="normal"
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        value={formik.values.name}
        onChange={formik.handleChange}
        style={{ textTransform: "capitalize" }}
        fullWidth
        autoFocus
      />

      <HkTextField
        data-testid="email"
        id="email"
        name="email"
        type="email"
        disabled={origDetails?.category === "Inventory"}
        placeholder={t("email")}
        margin="normal"
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        value={formik.values.email}
        onChange={formik.handleChange}
        fullWidth
      />

      <HkTextField
        data-testid="phoneNum"
        id="phoneNum"
        name="phoneNum"
        type="number"
        placeholder={t("phoneNum")}
        disabled={origDetails?.category === "Inventory"}
        margin="normal"
        error={formik.touched.phoneNum && Boolean(formik.errors.phoneNum)}
        helperText={formik.touched.phoneNum && formik.errors.phoneNum}
        value={formik.values.phoneNum}
        onChange={formik.handleChange}
        fullWidth
      />

      <HkTextField
        data-testid="category"
        dir={theme.direction}
        select
        id="category"
        name="category"
        disabled={origDetails?.category === "Inventory"}
        placeholder={t("category")}
        value={formik.values.category}
        error={formik.touched.category && Boolean(formik.errors.category)}
        helperText={
          (formik.touched.category && formik.errors.category) ||
          t("add_account_category_helper")
        }
        FormHelperTextProps={
          theme.direction === "rtl" ? { sx: { textAlign: "right" } } : {}
        }
        onChange={formik.handleChange}
        fullWidth
        margin="normal"
      >
        {categories.map((c) => (
          <MenuItem
            value={c}
            key={c}
            sx={{ fontFamily: theme.customVars.urduFont }}
          >
            {t(c)}
          </MenuItem>
        ))}
      </HkTextField>
      {errors && (
        <Typography className={classes.error} variant="body2">
          <Box
            component="span"
            sx={
              theme.direction === "rtl"
                ? { fontFamily: theme.customVars.urduFont }
                : {}
            }
          >
            {errors.general}
          </Box>
        </Typography>
      )}
    </form>
  );

  const isAccountDeletable = () => {
    let deleteAllowed = origDetails?.transactions.length === 0;

    if (deleteAllowed && origDetails?.category === "Inventory") {
      deleteAllowed = isInventoryAccountDeletable;
    }
    return deleteAllowed;
  };

  const dialogActions = (
    <>
      <HkBotton
        sx={{
          mr: theme.direction === "ltr" ? "auto" : 0,
          ml: theme.direction === "rtl" ? "auto" : 0,
          display: isAccountDeletable() ? "block" : "none"
        }}
        onClick={handleDeleteClick}
        color="secondary"
        variant="outlined"
        size="small"
      >
        {t("delete")}
      </HkBotton>
      <HkBotton onClick={handleDialogClose} color="secondary">
        {t("cancel")}
      </HkBotton>
      <Marginer direction="horizontal" margin="10px" />
      <HkBotton
        data-testid="account-submit-button"
        color="primary"
        form="account-form"
        type="submit"
        variant="contained"
        disabled={loading}
      >
        {loading && (
          <CircularProgress
            className={classes.progressCircle}
            size={25}
            color="secondary"
          />
        )}
        {t("account_submit")}
      </HkBotton>
    </>
  );
  return (
    <>
      <ResponsiveDialog
        open={open}
        handleClose={handleDialogClose}
        dialogTitle={dialogTitle}
        dialogContent={dialogContent}
        dialogActions={dialogActions}
      />
      <MuiBackdrop open={loading} />

      <AlertDialog
        open={openDeleteConfirmationDialog}
        handleDialogClose={handleDeleteDialogClose}
        handleDialogSubmit={handleAccountDelete}
        title={t("delete_account_title")}
        goToOnSuccess={"/app/accounts"}
        submitButtonText={t("delete_it")}
      >
        {theme.direction === "ltr" ? (
          <>
            <span style={{ color: "red" }}>WARNING! </span> Confirm deletion of
            account.
          </>
        ) : (
          <>
            <span style={{ color: "red" }}>{t("warning")} </span>{" "}
            {t("delete_account_msg")}
          </>
        )}
      </AlertDialog>
    </>
  );
}

AddAccountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAccountDelete: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  origDetails: PropTypes.object,
  isInventoryAccountDeletable: PropTypes.bool
};

export default AddAccountDialog;
