import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

//MUI
import { Typography, Box, Divider, List, Hidden, Drawer } from "@mui/material";
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  Users as UsersIcon
} from "react-feather";

import DrawerItem from "./DrawerItem";
import { useTheme } from "@mui/system";

//i18n
import { useTranslation } from "react-i18next";

/***************************IMPORTS END************************************/

const items = (t) => [
  {
    href: "/app/dashboard",
    icon: BarChartIcon,
    title: t("dashboard")
  },
  {
    href: "/app/accounts",
    icon: UsersIcon,
    title: t("account")
  },
  // {
  //   href: "/app/transactions",
  //   icon: CreditCardIcon,
  //   title: t("transactions")
  // },
  {
    href: "/app/inventory",
    icon: ShoppingBagIcon,
    title: t("inventory")
  },
  // {
  //   href: "/app/profile",
  //   icon: UserIcon,
  //   title: t("profile")
  // },
  {
    href: "/app/settings",
    icon: SettingsIcon,
    title: t("settings")
  }
];

function DashboardDrawer({ user, onMobileClose, openMobile, direction }) {
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%"
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          p: 2
        }}
      >
        <Typography color="textPrimary" variant="subtitle1">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.organization}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items(t).map((item) => (
            <DrawerItem
              data-testid="nav-links"
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          direction="ltr"
          anchor={theme.direction === "ltr" ? "left" : "right"}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
          SlideProps={{
            //reverse the direction of slide transition to achieve expected behavior
            direction: theme.direction === "ltr" ? "right" : "left"
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor={theme.direction === "ltr" ? "left" : "right"}
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: "calc(100% - 64px)"
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

DashboardDrawer.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  user: PropTypes.object
};

DashboardDrawer.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardDrawer;
