import { Navigate } from "react-router-dom";
import LoginSignup from "./pages/LoginSignup";
import DashboardLayout from "./layouts/DashboardLayout";
import MainLayout from "./layouts/MainLayout";
import AccountsPage from "./pages/AccountsPage";
import AccountDetailsPage from "./pages/AccountDetailsPage";
import Dashboard from "./pages/Dashboard";
import InventoryPage from "./pages/InventoryPage";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import SettingsPage from "./pages/SettingsPage";
import ForgotPassword from "./pages/ForgotPassword";

function getRoutes(authenticated) {
  const getProtectedElement = (element) =>
    authenticated ? element : <Navigate to="/login" />;

  const getPublicElement = (element) =>
    !authenticated ? element : <Navigate to="/app/dashboard" />;

  const routes = [
    {
      path: "app",
      element: <DashboardLayout />,
      children: [
        { path: "dashboard", element: getProtectedElement(<Dashboard />) },
        { path: "accounts", element: getProtectedElement(<AccountsPage />) },
        {
          path: "accounts/:accountId",
          element: getProtectedElement(<AccountDetailsPage />)
        },
        { path: "inventory", element: getProtectedElement(<InventoryPage />) },
        {
          path: "inventory/:productId",
          element: getProtectedElement(<ProductDetailsPage />)
        },
        { path: "settings", element: getProtectedElement(<SettingsPage />) }
      ]
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/login",
          element: getPublicElement(<LoginSignup active="login" />)
        },
        {
          path: "/forgotPassword",
          element: getPublicElement(<ForgotPassword />)
        },
        {
          path: "/",
          element: getPublicElement(<LoginSignup active="login" />)
        }
      ]
    }
  ];

  return routes;
}

export default getRoutes;
