import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import { alpha, useTheme } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import AlertDialog from "../Dialogs/AlertDialog";
import { useTranslation } from "react-i18next";
import TransactionDialog from "../Dialogs/TransactionDialog";

const TransactionsListToolbar = (props) => {
  const { numSelected, handleTransactionSubmit, handleTransactionsDelete } =
    props;

  const [openTransactionDialog, setOpenTransactionDialog] = useState(false);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleTransactionDialogOpen = () => {
    setOpenTransactionDialog(true);
  };
  const handleTransactionDialogClose = () => {
    setOpenTransactionDialog(false);
  };

  const handleDeleteClick = () => {
    setOpenDeleteConfirmationDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteConfirmationDialog(false);
  };

  const handleDeleteDialogSubmit = () => {
    return handleTransactionsDelete();
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              )
          })
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{
              flex: "1 1 100%",
              fontFamily: theme.direction === "rtl" && theme.customVars.urduFont
            }}
            color="inherit"
            variant="body1"
            component="div"
          >
            {`${numSelected} ${t("selected")}`}
          </Typography>
        ) : (
          <Typography
            sx={{
              flex: "1 1 100%",
              fontFamily: theme.direction === "rtl" && theme.customVars.urduFont
            }}
            variant="subtitle1"
            id="tableTitle"
            component="div"
          >
            {t("transactions_list")}
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title={t("delete")}>
            <IconButton
              data-testid="delete-trans-btn"
              onClick={handleDeleteClick}
            >
              <DeleteIcon color="secondary" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={t("add_transaction")}>
            <IconButton
              data-testid="add-trans-btn"
              onClick={handleTransactionDialogOpen}
            >
              <AddIcon fontSize="large" color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>

      <TransactionDialog
        open={openTransactionDialog}
        handleClose={handleTransactionDialogClose}
        handleTransactionSubmit={handleTransactionSubmit}
      />
      <AlertDialog
        open={openDeleteConfirmationDialog}
        handleDialogClose={handleDeleteDialogClose}
        handleDialogSubmit={handleDeleteDialogSubmit}
        title={t("delete_transaction_title")}
        submitButtonText={t("delete_it")}
      >
        {theme.direction === "ltr" ? (
          <>
            <span style={{ color: "red" }}>WARNING! </span> Confirm deletion of{" "}
            {numSelected} transaction{numSelected > 1 ? "s" : ""}
          </>
        ) : (
          <>
            <span style={{ color: "red" }}>{t("warning")} </span>{" "}
            {t("delete_transaction_msg")}
          </>
        )}
      </AlertDialog>
    </>
  );
};

TransactionsListToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

export default TransactionsListToolbar;
